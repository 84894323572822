<template>
  <div class="wrapper-swiper-horizontal" :style="{height: test}">

    <div class="swiper-horizontal" ref="swiperHorizontal">
      <slot ref="test"></slot>
    </div>
    <div class="wrapper-pages" v-if="countPages > 1">
      <button class="page" v-for="item in countPages" :key="item.id"
              :class="{'selected': selectedPage === item}"
              @click="handleClickToPage(item)">
      </button>
    </div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import {tasksStore} from "@/store/tasksStore";

const props = defineProps({
  countItems: Number
});
const {countItems} = toRefs(props);
const taskStore = tasksStore();
const {toggleIsMoveSlider} = taskStore;

let xDown = null;
let swiperHorizontal = ref(null);
let countPages = ref(null);
let countItemOnPage = null;
let selectedPage = ref(1);
let test = ref(null);

onMounted(() => {
  if (countItems.value) getCountPages();
  test.value = `${(swiperHorizontal.value?.children?.[0]?.clientHeight || 0) + 75}px`;
})

watch(countItems, () => {
  if (countItems.value) getCountPages();
})


function getCountPages() {
  countItemOnPage = Math.floor(window.innerWidth / (swiperHorizontal.value?.children?.[0]?.clientWidth + 30));
  countPages.value = Math.ceil(countItems.value / (window.innerWidth / (swiperHorizontal.value?.children?.[0]?.clientWidth + 30)));
  if (countPages.value > 1) {
    addListeners();
  }
}

function addListeners() {
  swiperHorizontal.value?.addEventListener('mousedown', handleMouseDown, false);
  swiperHorizontal.value?.addEventListener('touchmove', handleTouchMove, false);
  swiperHorizontal.value?.addEventListener('touchend', handleTouchEnd, false);
  document.addEventListener('mouseup', handleMouseUp, false);
}

function handleMouseDown() {
  document.addEventListener('mousemove', handleMove)
}

function handleMouseUp() {
  document.removeEventListener('mousemove', handleMove);
  handleTouchEnd();
}

function handleMove(event) {
  if (swiperHorizontal.value?.offsetLeft > 50) return;

  if (event.movementX > 0) {
    setupLeftPosition(swiperHorizontal.value?.offsetLeft + event.movementX);
  } else {
    if (getCurrentRightPosition() < window.innerWidth - 50) return;
    setupLeftPosition(swiperHorizontal.value?.offsetLeft + event.movementX);
  }
  checkShowNextPage();
  toggleIsMoveSlider(true);
}

function checkShowNextPage() {
  for (let i = 0; i < countPages.value; i++) {
    const itemPositionLeft = swiperHorizontal.value?.children[i * countItemOnPage]?.offsetLeft;
    const itemWidth = swiperHorizontal.value?.children[i * countItemOnPage]?.offsetWidth;
    const positionElementOnScreen = itemPositionLeft + itemWidth + swiperHorizontal.value?.offsetLeft;

    if (positionElementOnScreen < window.innerWidth && positionElementOnScreen > 0) {
      selectedPage.value = i + 1;
    }
  }
}

function handleTouchMove(event) {
  const leftPosition = swiperHorizontal.value?.offsetLeft;
  if (leftPosition > 50) return;
  if (getCurrentRightPosition() < window.innerWidth - 50) return;
  const xUp = event.touches[0].screenX;

  if (!xDown) {
    xDown = xUp;
    return;
  }

  const xDiff = xUp - xDown;
  setupLeftPosition(leftPosition + xDiff);
  checkShowNextPage();
  toggleIsMoveSlider(true);
  xDown = xUp;
}

function handleTouchEnd() {
  xDown = null;
  setupLastPosition();
}

function setupLastPosition() {
  const leftPosition = swiperHorizontal.value?.offsetLeft;
  if (leftPosition > 0) {
    ternOnTempTransition();
    setupLeftPosition(0);
  } else if (getCurrentRightPosition() < window.innerWidth) {
    ternOnTempTransition();
    setupLeftPosition(Math.abs(swiperHorizontal.value?.offsetWidth - window.innerWidth) * -1);
  } else {
    setTimeout(() => {
      toggleIsMoveSlider(false);
    }, 0)
  }
}

function getCurrentRightPosition() {
  return swiperHorizontal.value?.offsetWidth - Math.abs(swiperHorizontal.value?.offsetLeft);
}

function handleClickToPage(index) {
  if (index === 1) {
    scrollToFirsElement();
  } else if (index === countPages.value) {
    scrollToLastElement();
  } else {
    scrollToSomeElement(index);
  }
  selectedPage.value = index;
}

function scrollToSomeElement(index) {
  const rightPosition = getCurrentRightPosition();

  if (swiperHorizontal.value?.offsetLeft > 0) {
    ternOnTempTransition();
    setupLeftPosition(swiperHorizontal.value?.offsetLeft - (swiperHorizontal.value?.children[index - 1]?.offsetLeft - 30));
  } else {
    const futurePosition = 0 - (swiperHorizontal.value?.children[(index - 1) * countItemOnPage]?.offsetLeft - 30);
    if (Math.abs(futurePosition) < Math.abs(swiperHorizontal.value?.offsetLeft - (rightPosition - window.innerWidth))) {
      ternOnTempTransition();
      setupLeftPosition(futurePosition);
    } else {
      scrollToLastElement();
    }
  }
}

function scrollToFirsElement() {
  ternOnTempTransition();
  setupLeftPosition(0);
}

function scrollToLastElement() {
  const rightPosition = getCurrentRightPosition();
  const leftPosition = swiperHorizontal.value?.offsetLeft - (rightPosition - window.innerWidth);
  ternOnTempTransition();
  setupLeftPosition(leftPosition);
}

function setupLeftPosition(position) {
  swiperHorizontal.value.style.left = `${position}px`;
}

function ternOnTempTransition() {
  swiperHorizontal.value.style.transition = `all 0.5s`;
  setTimeout(() => {
    if (swiperHorizontal.value?.style){
      swiperHorizontal.value.style.transition = ``;
    }
  }, 200);
}

onUnmounted(() => {
  swiperHorizontal.value?.removeEventListener('mousedown', handleMouseDown, false);
  swiperHorizontal.value?.removeEventListener('touchmove', handleTouchMove, false);
  swiperHorizontal.value?.removeEventListener('touchend', handleTouchEnd, false);
  document.removeEventListener('mouseup', handleMouseUp, false)
})
</script>

<style scoped lang="scss">
.wrapper-swiper-horizontal {
  position: relative;
  width: 100vw;
  //height: 350px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  .swiper-horizontal {
    position: absolute;
    left: 0;
    padding: 20px 30px;
    margin-bottom: 35px;
    display: flex;
    gap: 20px;
    transition: all 0s;
  }

  .wrapper-pages {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;

    .page {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      border: 2px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      background-color: var(--dark-pink);
      transition: all 0.2s;

      &:hover {
        background-color: var(--pink);
      }

      &.selected {
        background-color: var(--yellow);
      }

      &:active {
        box-shadow: 0 0 1px var(--dark);
        transform: translateY(5px);
      }
    }
  }
}

@media screen and (min-width: 2200px){
  .wrapper-swiper-horizontal {

    .swiper-horizontal {
      padding: 0 30px 25px 30px;
      gap: 25px;
    }

    .wrapper-pages {
      gap: 10px;
      margin: 5px;

      .page {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media screen and (max-width: 450px){
  .wrapper-swiper-horizontal {

    .swiper-horizontal {
      padding: 0 15px 15px 15px;
      gap: 15px;
    }

    .wrapper-pages {
      gap: 10px;
      margin: 10px 0;

      .page {
      }
    }
  }
}
</style>
