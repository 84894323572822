import {useHead} from "@vueuse/head";

export default function seo(title, meta) {
    useHead({
        htmlAttrs: {
            lang: 'ru'
        },
        title: title,
        meta: [...meta],
    })
}
