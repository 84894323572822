<template>
  <v-dialog
      v-model="isOpenAssessmentModal"
      transition="dialog-bottom-transition"
      class="check-level-modal"
      fullscreen
  >
    <div class="check-level-block">
      <button class="close-modal" @click="toggleOpenAssessmentModal(false)">
        <img src="img/check-level/close.svg" alt="close">
      </button>

      <Transition :name="getSlideName">
        <div class="content" v-if="!isShowAssessment">
          <AskCheckOrSetupLevel @openAssessmentBlock="isShowAssessment = true"></AskCheckOrSetupLevel>
        </div>
      </Transition>

      <Transition :name="getSlideName">
        <div class="content" v-if="isShowAssessment">
          <AssessmentBlock @closeAssessmentBlock="closeAssessmentBlock()"></AssessmentBlock>
        </div>
      </Transition>
    </div>
  </v-dialog>
</template>

<script setup>
import {storeToRefs} from "pinia/dist/pinia";
import AskCheckOrSetupLevel from "@/components/modals/check-level-modal/AskCheckOrSetupLevel";
import AssessmentBlock from "@/components/modals/check-level-modal/AssessmentBlock";
import {computed, ref} from "vue";
import {checkLevelStore} from "@/store/checkLevelStore";

const checkLevel = checkLevelStore();
const {clearAssessmentStore, toggleOpenAssessmentModal} = checkLevel;
const {isOpenAssessmentModal} = storeToRefs(checkLevel);
const getSlideName = computed(() => isShowAssessment.value ? 'slide-from-right' : 'slide-from-left');

let isShowAssessment = ref(false);

function closeAssessmentBlock() {
  isShowAssessment.value = false;
  clearAssessmentStore();
}
</script>

<style scoped lang="scss">
@import "src/assets/slide-animation";

.check-level-modal {
  top: 82px;

  .check-level-block {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: var(--light-yellow);

    .close-modal {
      position: absolute;
      top: 10px;
      right: 10px;
      transition: all 0.2s;
      z-index: 100;

      img {
        width: 35px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

}
</style>
