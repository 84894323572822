<template>
  <div class="assessment-block">
    <Transition :name="getSlideName">
      <NavInformation v-if="currentAssessmentIndex > -1"></NavInformation>
    </Transition>

    <Transition :name="getSlideName">
      <div class="assessment final-block" v-if="currentAssessmentIndex === -1">
        <p>Этот тест предназначен для оценки вашего базового понимания английского языка.</p>
        <p>Для этого теста нет ограничений по времени. Уделите столько времени, сколько вам необходимо, чтобы точно
          ответить на каждый вопрос.</p>
        <NavigationButton :index="-1" @goToMenu="emit('closeAssessmentBlock')"></NavigationButton>
      </div>
    </Transition>

    <template v-for="(assessment, index) in ASSESSMENT_DATA">
      <Transition :name="getSlideName">
        <div class="assessment" v-if="currentAssessmentIndex === index">
          <ContainerRadioQuestion
              :titleQuestion="assessment.title"
              :questions="assessment.question"
              :index="index"
              @goToMenu="emit('closeAssessmentBlock')">
          </ContainerRadioQuestion>
        </div>
      </Transition>
    </template>

    <Transition :name="getSlideName">
      <div class="assessment final-block" v-if="currentAssessmentIndex === (ASSESSMENT_DATA.length)">
        <template v-if="resultText">
          <h3>Поздравляем!</h3>
          <p>Вы успешно прошли тест, ваш уровень владения языком <span>{{ resultText.level }}</span>!</p>
          <p>{{ resultText.explain }}</p>
          <p>{{ resultText.advice }}</p>
          <button class="secondary-button">Начать обучение</button>
        </template>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {checkLevelStore} from "@/store/checkLevelStore";
import {ASSESSMENT_DATA} from "@/configuration/Assessment";
import {storeToRefs} from "pinia/dist/pinia";
import ContainerRadioQuestion from "@/components/modals/check-level-modal/questions/ContainerRadioQuestion";
import NavigationButton from "@/components/modals/check-level-modal/questions/NavigationButton";
import NavInformation from "@/components/modals/check-level-modal/NavInformation";

const emit = defineEmits(['closeAssessmentBlock']);
const checkLevel = checkLevelStore();
const {currentAssessmentIndex, isBackInAssessmentBlock, resultText} = storeToRefs(checkLevel);
const getSlideName = computed(() => isBackInAssessmentBlock.value ? 'slide-from-left' : 'slide-from-right');
</script>

<style scoped lang="scss">
@import "src/assets/slide-animation";
@import "src/assets/buttons";

.assessment-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .nav-information {
    position: absolute;
    top: 100px;

  }

  .assessment {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .final-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 70vh;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--light_pink);

    h3 {
      color: var(--dark-pink);
      font-weight: 800;
    }

    p {
      span {
        color: var(--dark-pink);
        font-weight: 800;
      }
    }

    .secondary-button {
      background-color: var(--pink);
      color: var(--light-yellow);
      padding: 5px 15px;

      &:hover {
        background-color: var(--dark-pink);
      }
    }
  }
}
</style>
