export const EMPTY_DATA_FOR_ASSESSMENT = {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
    11: {},
    12: {},
}

export const ANSWERS = {
    0: 'am',
    1: 'his',
    2: 'my brother’s friend',
    3: 'Would you like',
    4: 'to buy',
    5: 'any',
    6: 'Have you ever',
    7: 'for',
    8: 'used to smoke',
    9: 'Neither was I.',
    10: 'wouldn’t be',
    11: 'put',
    12: 'cracking',
}

export const ASSESSMENT_DATA = [
    {
        title: 'I ________________ from Spain.',
        question: [
            {
                title: 'am',
            },
            {
                title: 'is',
            },
            {
                title: 'are',
            }
        ]
    },
    {
        title: 'This is my friend. _____________ name is Oscar.',
        question: [
            {
                title: 'her',
            },
            {
                title: 'our',
            },
            {
                title: 'yours',
            },
            {
                title: 'his',
            }
        ]
    },
    {
        title: 'Matt is _____________.',
        question: [
            {
                title: 'my brother’s friend',
            },
            {
                title: 'friend my brother',
            },
            {
                title: 'friend from my brother',
            },
            {
                title: 'my brother friend’s',
            }
        ]
    },
    {
        title: '__________________ a piece of cake? No, thank you.',
        question: [
            {
                title: 'Do you like',
            },
            {
                title: 'Would you like',
            },
            {
                title: 'Want you',
            },
            {
                title: 'Are you like',
            }
        ]
    },
    {
        title: 'The sightseeing bus is very old. We’re going ____________________ a new bus soon.',
        question: [
            {
                title: 'to buy',
            },
            {
                title: 'buying',
            },
            {
                title: 'to will buy',
            },
            {
                title: 'buy',
            }
        ]
    },
    {
        title: 'There aren’t ________________ buses late in the evening.',
        question: [
            {
                title: 'some',
            },
            {
                title: 'any',
            },
            {
                title: 'no',
            },
            {
                title: 'a',
            }
        ]
    },
    {
        title: '________________ seen a falling star before?',
        question: [
            {
                title: 'Did you ever',
            },
            {
                title: 'Are you ever',
            },
            {
                title: 'Have you ever',
            },
            {
                title: 'Do you ever',
            }
        ]
    },
    {
        title: 'We’ve been friends ____________________ many years.',
        question: [
            {
                title: 'since',
            },
            {
                title: 'from',
            },
            {
                title: 'during',
            },
            {
                title: 'for',
            }
        ]
    },
    {
        title: 'He doesn’t smoke now, but he __________________ a lot when he was young.',
        question: [
            {
                title: 'has smoked',
            },
            {
                title: 'smokes',
            },
            {
                title: 'used to smoke',
            },
            {
                title: 'was smoked',
            }
        ]
    },
    {
        title: 'I wasn’t interested in the performance very much. ________________.',
        question: [
            {
                title: 'I didn’t, too.',
            },
            {
                title: 'Neither was I.',
            },
            {
                title: 'Nor I did.',
            },
            {
                title: 'So I wasn’t.',
            }
        ]
    },
    {
        title: 'If I hadn’t replied to your email, I___________________ here with you now.',
        question: [
            {
                title: 'can’t be',
            },
            {
                title: 'wouldn’t be',
            },
            {
                title: 'won’t be',
            },
            {
                title: 'haven’t been',
            }
        ]
    },
    {
        title: 'It’s cold so you should __________________ on a warm jacket.',
        question: [
            {
                title: 'put',
            },
            {
                title: 'wear',
            },
            {
                title: 'dress',
            },
            {
                title: 'take',
            }
        ]
    },
    {
        title: 'Come on! Quick! Let’s get _____________!',
        question: [
            {
                title: 'highlight',
            },
            {
                title: 'cracking',
            },
            {
                title: 'massive',
            },
            {
                title: 'with immediate effect',
            }
        ]
    }
]

export const TEXT_FOR_A1 = {
    level: 'A1',
    explain: 'Это отличное достижение, которое свидетельствует о вашей способности понимать и использовать базовые английские конструкции. Теперь у вас есть фундамент, на котором можно строить свои знания и навыки в изучении языка.',
    advice: 'Продолжайте учиться и практиковаться, и вы увидите, как ваш уровень английского будет постепенно расти!'
}
export const TEXT_FOR_A2 = {
    level: 'A2',
    explain: 'Это замечательное достижение, которое свидетельствует о вашей способности понимать и использовать базовые английские конструкции с большей уверенностью. Теперь вы способны общаться на простых темах и понимать основные идеи в разговорах на английском.',
    advice: 'Продолжайте практиковаться и расширять свой словарный запас, и ваш навык владения английским языком будет только расти!'
}
export const TEXT_FOR_B1 = {
    level: 'B1',
    explain: 'Это отличное достижение, которое свидетельствует о вашей способности понимать основные идеи текстов на английском языке, участвовать в простых разговорах и писать свои мысли на английском языке. Теперь вы готовы к более глубокому изучению языка и расширению своих навыков общения.',
    advice: 'Продолжайте учиться и практиковаться, и вы увидите, как ваш уровень владения английским языком будет расти!'
}
export const TEXT_FOR_B2 = {
    level: 'B2',
    explain: 'Это впечатляющее достижение, которое свидетельствует о вашей высокой компетенции в английском языке. Теперь вы способны понимать сложные тексты, выражать свои мысли грамотно и уверенно общаться на английском языке.',
    advice: 'Продолжайте развивать свои навыки, изучая новые темы и практикуясь в реальных ситуациях, и вы достигнете еще более высокого уровня владения английским языком!'
}
