<template>
  <div class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.wrapper {
  width: 130px;
  height: 30px;
  position: relative;
  margin: 20px 5px 0 5px;
  z-index: 1;
}

.circle {
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--yellow);
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 19px;
    height: 10px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: .2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: .3s;
}

.shadow {
  width: 10px;
  height: 3px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.9);
  position: absolute;
  top: 28px;
  transform-origin: 50%;
  z-index: -1;
  left: 17%;
  filter: blur(1px);
  animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: .7;
  }

  100% {
    transform: scaleX(.2);
    opacity: .4;
  }
}

.shadow:nth-child(4) {
  left: 47%;
  animation-delay: .2s
}

.shadow:nth-child(5) {
  left: auto;
  right: 17%;
  animation-delay: .3s;
}

</style>
