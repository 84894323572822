<template>
  <div class="wrapper-button">
    <button class="secondary-button"
            v-if="index === 0 || index === -1"
            @click="emit('goToMenu')">
      Вернуться в меню
    </button>
    <button class="secondary-button"
            @click="changeAssessmentIndex(true)"
            v-else>
      Назад
    </button>
    <button class="secondary-button" v-if="index === -1" @click="changeAssessmentIndex(false)">Начать</button>
    <template v-else>
      <button class="secondary-button" v-if="index === 12" @click="checkAssessment()">Завершить</button>
      <button class="secondary-button" @click="changeAssessmentIndex(false)" v-else>Следующий</button>
    </template>
  </div>
</template>

<script setup>
import {checkLevelStore} from "@/store/checkLevelStore";
import {storeToRefs} from "pinia/dist/pinia";

const emit = defineEmits(['goToMenu']);
const props = defineProps({
  index: Number
})

const checkLevel = checkLevelStore();
const {changeCurrentAssessmentIndex, toggleIsBackInAssessment, checkAssessment} = checkLevel;
const {currentAssessmentIndex} = storeToRefs(checkLevel);

function changeAssessmentIndex(isBack) {
  const currentIndex = isBack ? currentAssessmentIndex.value - 1 : currentAssessmentIndex.value + 1;
  changeCurrentAssessmentIndex(currentIndex);
  toggleIsBackInAssessment(isBack);
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.wrapper-button {
  display: flex;
  gap: 10px;

  .secondary-button {
    padding: 5px 15px;
    background-color: var(--pink);
    color: var(--light-yellow);

    &:hover {
      background-color: var(--dark-pink);
    }
  }
}
</style>
