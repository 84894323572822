<template>
  <div class="tasks-list">
    <h4>Список заданий</h4>
    <TaskElementOnMainPage v-for="task in allTasks"
                           :task="task"
                           @selectTask="startSelectedTask">
    </TaskElementOnMainPage>
  </div>

  <ShowWordsForTask v-if="isOpenWordList"
                    :task="selectedTask"
                    @hiddenBlock="toggleOpenWordsList">
  </ShowWordsForTask>
  <InfoForInterview v-if="isOpenInfoForInterview"
                    :task="selectedTask"
                    @hiddenBlock="toggleOpenInfoForInterview">
  </InfoForInterview>
</template>

<script setup>
import ShowWordsForTask from "@/components/modals/ShowWordsForTask";
import InfoForInterview from "@/components/modals/InfoForInterview";
import TaskElementOnMainPage from "@/components/widgets/TaskElementOnMainPage";
import taskRequests from "@/mixins/requests/taskRequests";
import {onMounted, ref} from "vue";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";

const {getAllTasks, taskStart} = taskRequests();
const taskStore = tasksStore();
const {allTasks} = storeToRefs(taskStore);

let isOpenWordList = ref(false);
let taskTrainingWord = ref(null);
let selectedTask = ref(null);
let isOpenInfoForInterview = ref(false);

onMounted(() => {
  if (!allTasks.value) getAllTasks();
})

function startSelectedTask(task) {
  selectedTask.value = task;
  if (task.type.title === "Тренировка слова" || task.type.title === "Игра: загадай слово") {
    taskTrainingWord.value = task;
    toggleOpenWordsList(true);
  } else if (task.type.title === "Собеседование") {
    toggleOpenInfoForInterview(true);
  } else {
    taskStart(task.id);
  }
}

function toggleOpenWordsList(isOpen) {
  isOpenWordList.value = isOpen;
}

function toggleOpenInfoForInterview(isOpen) {
  isOpenInfoForInterview.value = isOpen;
}
</script>

<style scoped lang="scss">
.tasks-list {
  max-height: 500px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding: 20px 25px;
  border-radius: 10px;
  border: 2px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);
  overflow: auto;

  h4 {
    color: var(--dark-pink);
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (min-width: 2200px) {
  .tasks-list {
    max-height: 700px;

    h4 {
      font-size: 35px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .tasks-list {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 700px) {
  .tasks-list {
    width: 100%;
  }
}
</style>
