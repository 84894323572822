<template>
  <div class="change-level">
    <h2>Смена уровня</h2>

    <div class="container">
      <div class="wrapper-level">
        <button class="level" v-for="level in levels" :key="level.id">
          <span class="title">{{ level.title }}</span>
          <span class="status">{{ level.status }}</span>
        </button>
      </div>

      <button class="secondary-button"
              v-if="isShowButton"
              @click="toggleOpenAssessmentModal(true)">
        Проверить уровень
      </button>
    </div>
  </div>
</template>

<script setup>
import {checkLevelStore} from "@/store/checkLevelStore";

const levels = [
  {
    title: 'A1',
    status: 'Начальный'
  },
  {
    title: 'A2',
    status: 'Средний начальный'
  },
  {
    title: 'B1',
    status: 'Средний'
  },
  {
    title: 'B2',
    status: 'Высокий Средний'
  },
  {
    title: 'C1',
    status: 'Продвинутый'
  },
  {
    title: 'C2',
    status: 'Профессиональный'
  },
];
const checkLevel = checkLevelStore();
const {toggleOpenAssessmentModal} = checkLevel;
const props = defineProps({
  isShowButton: Boolean
})
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.change-level {
  min-width: 400px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 5px;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .wrapper-level {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex: 1;
      gap: 10px;

      .level {
        min-width: 45%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 5px;
        border-radius: 10px;
        border: 2px solid var(--dark);
        box-shadow: 1px 4px 1px var(--dark);
        background-color: var(--pink);
        color: var(--light-yellow);
        transition: all 0.2s;

        &:first-child {
          background-color: var(--yellow);
          color: var(--dark);
          box-shadow: 0 0 1px var(--dark);
          transform: translateY(5px);

          .title {
            color: var(--dark-pink);
          }

          .status {
            color: var(--pink);
          }
        }

        &:active {
          box-shadow: 0 0 1px var(--dark);
          transform: translateY(5px);
        }

        .title {
          color: var(--yellow);
          font-size: 16px;
          font-weight: 700;
        }

        .status {
          color: var(--light_pink);
          font-size: 13px;
        }
      }
    }

    .secondary-button {
      padding: 5px 15px;
      background-color: var(--dark-pink);
      color: var(--light-yellow);

      &:hover {
        background-color: var(--pink);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .change-level {
    max-width: unset;
    min-width: 350px;
  }
}
</style>
