import {defineStore, storeToRefs} from "pinia/dist/pinia";
import {ref} from "vue";
import {tasksStore} from "@/store/tasksStore";

export const chatStore = defineStore('chat', () => {
    const taskStore = tasksStore();
    const {currentTask} = storeToRefs(taskStore);

    let mainChatMessages = ref([]);
    let messageLimit = ref(null);
    let currentPage = ref(1);
    let lastPage = ref(null);
    let triggerSaveScrollForPagination = ref(false);
    let isActiveGenerationMainChat = ref(false);

    //tasks chat
    let messages = ref([]);
    let isActiveLoaderTranslate = ref(false);
    let idHandleMessage = ref(null);
    let foundWord = ref(null);
    let isActiveSearching = ref(false);
    let isActiveLoaderMessageGeneration = ref(false);
    let isActiveGeneration = ref(false);

    //shared variables
    let isTriggerScrollDown = ref(false);

    function clearChatStore() {
        messages.value = [];
        isActiveLoaderTranslate.value = false;
        idHandleMessage.value = null;
        foundWord.value = null;
        isActiveSearching.value = false;
        isActiveLoaderMessageGeneration.value = false;
        isActiveGeneration.value = false;
    }

    function clearMainChat() {
        mainChatMessages.value = [];
        messageLimit.value = null;
        currentPage.value = 1;
        lastPage.value = null;
        triggerSaveScrollForPagination.value = false;
        isActiveGenerationMainChat.value = false;
    }

    function cleanFullChatStore() {
        clearChatStore();
        clearMainChat();
    }

    function changeMessages(allMessages, isSeparateBotMessage = false) {
        if (isSeparateBotMessage) {
            messages.value = allMessages.map(data => {
                if (data.is_bot) {
                    return {
                        // id: Math.floor(Math.random() * 100000),
                        ...data,
                        message: handleMessage(data.message)
                    }
                } else {
                    return data
                }
            })
        }

        setTimeout(() => {
            triggerScrollDown();
        }, 900)
    }

    function changeMainChatMessages(allMessages) {
        mainChatMessages.value = allMessages.map(data => {
            return {
                id: Math.floor(Math.random() * 100000),
                ...data,
            }
        })

        setTimeout(() => {
            triggerScrollDown();
        }, 900)
    }

    function addNextPageMessages(allMessages, isMainChat) {
        const newData = allMessages.map(data => {
            return {
                ...data,
                id: Math.floor(Math.random() * 100000)
            }
        })
        isMainChat ? mainChatMessages.value.unshift(...newData) : messages.value.unshift(...newData);

        triggerSaveScrollForPagination.value = !triggerSaveScrollForPagination.value;
    }

    function checkUpdatedData(response) {
        const messageId = getIndexMessage(response.data, true);
        if (messageId >= 0) {
            if (!messages.value[messageId].translate && response.data[1].translate) {
                changeTranslationsMessage(response.data, messageId);
            } else if (!messages.value[messageId].voice && response.data[1].voice) {
                changeVoiceMessage(response.data, messageId);
            }
        } else {
            addNewMessage(response.data[1].message, null, response.data[2].message_id, true, response.data?.date, true);
        }
    }

    function changeTranslationsMessage(data, messageId) {
        if (!messageId) messageId = getIndexMessage(data, true);
        if (messageId >= 0) messages.value[messageId].translate = data[1].translate;
    }

    function changeVoiceMessage(data, messageId) {
        if (!messageId) messageId = getIndexMessage(data, true);
        if (messageId >= 0) messages.value[messageId].voice = data[1].voice;
    }

    function getIndexMessage(data, isBot) {
        return messages.value.findIndex(message => message.message_id === data[2].message_id && message.is_bot === isBot);
    }

    function handleMessage(message) {
        return message
            .replaceAll(/([\w']+)/ig, (substr) => `|divider|<span class="english-word">${substr}</span>|divider|`)// оборачивает английские слова в span с классом english-word
            .replaceAll(' |divider|', '&nbsp;|divider|') // пробелы между словами
            .replaceAll(/(\s")/ig, () => `&nbsp;"`) // пробел возле кавычек
            .replaceAll(/(\s')/ig, () => `&nbsp;'`) // пробел возле кавычек
            .replaceAll('\n\n', '|divider|<div class="new-line"></div>|divider|') // переносы строки на новую строку с добавление пустой строки
            .replaceAll('\n', '|divider|<div class="next-line"></div>|divider|')// переносы строки на новую строку
            .split('|divider|');
    }

    function addNewMessage(newMessage, audio, id, isBot, timestamp, isSeparateMessage) {
        let completedMessageData

        if (isSeparateMessage) {
            completedMessageData = {
                is_bot: isBot,
                message: handleMessage(newMessage),
                audio: audio,
                message_id: id,
                timestamp
            }
        } else {
            completedMessageData = {
                is_bot: isBot,
                message: newMessage,
                audio: audio,
                timestamp
            }
        }

        messages.value.push(completedMessageData);
        setTimeout(() => {
            triggerScrollDown();
        }, 500)
    }

    function addNewMessageToMainChat(newMessage, newAudio, id, isBot, timestamp,) {
        mainChatMessages.value.push({
            is_bot: isBot,
            message: newMessage,
            audio: newAudio,
            timestamp
        });
        setTimeout(() => {
            triggerScrollDown();
        }, 500)
    }

    function changeLastPersonMessageInMainChat(newData) {
        mainChatMessages.value[mainChatMessages.value.length - 1] = {
            ...newData,
            is_bot: false,
            message: newData.request
        }
    }

    function updateDataInLastPersonMessage(messageData, response) {
        const indexUpdatingMessage = getIndexMessage(response.data, false);
        messages.value[indexUpdatingMessage] = {
            ...messages.value[indexUpdatingMessage],
            ...messageData
        }

        if (response.data[2].status === "success") {
        }
    }

    // function addFakeMessageInTask(isFirstCheck) {
    //     if (currentTask.value?.task?.title !== 'Игра: изображения' && !messages.value.length) return;
    //
    //     const fakeBotMessage = {
    //         is_bot: true,
    //         message: 'Нейросеть не угадала вашу картинку',
    //         isHiddenButtons: true
    //     }
    //
    //     if (isFirstCheck) {
    //         console.log(currentTask.value)
    //         if (messages.value.length === 1 && (currentTask.value?.status === 'fails' || currentTask.value?.status === 'processing')) {
    //             messages.value.push(fakeBotMessage)
    //             console.log(1)
    //         } else if (messages.value.length === 2) {
    //             console.log(2)
    //             if (currentTask.value?.status === 'failed' || currentTask.value?.status === 'processing') {
    //                 messages.value.splice(1, 0, fakeBotMessage)
    //                 messages.value.push(fakeBotMessage)
    //             } else if (currentTask.value?.status === 'success') {
    //                 messages.value.splice(1, 0, fakeBotMessage)
    //             }
    //         }
    //     } else {
    //
    //     }
    // }

    function addIdToLastPersonMessage(id) {
        let lastMessage = null;
        messages.value.forEach(message => {
            if (!message.is_bot) lastMessage = message;
        });

        lastMessage.message_id = id;
    }

    function changeMessageLimit(limit) {
        messageLimit.value = limit;
    }

    function triggerScrollDown() {
        isTriggerScrollDown.value = !isTriggerScrollDown.value;
    }

    function changeActiveGeneration(isActive) {
        isActiveGeneration.value = isActive;
    }

    function changeActiveGenerationMainChat(isActive) {
        isActiveGenerationMainChat.value = isActive;
    }

    function changeActiveLoaderMessageGeneration(isActive) {
        isActiveLoaderMessageGeneration.value = isActive;
    }

    function changeActiveLoaderTranslate(isActive) {
        isActiveLoaderTranslate.value = isActive;
    }

    function changeIdHandleMessage(id) {
        idHandleMessage.value = id;

        if (id) triggerScrollDown();
    }

    function changeCurrentPage(page) {
        currentPage.value = page;
    }

    function changeLastPage(page) {
        lastPage.value = page;
    }

    function changeSearchWord(word) {
        foundWord.value = word;
    }

    function changeActiveSearching(isActive) {
        isActiveSearching.value = isActive;
    }

    return {
        messages,
        changeMessages,
        addNextPageMessages,
        checkUpdatedData,
        changeTranslationsMessage,
        changeVoiceMessage,
        addNewMessage,
        updateDataInLastPersonMessage,
        addIdToLastPersonMessage,
        mainChatMessages,
        changeMainChatMessages,
        changeLastPersonMessageInMainChat,
        addNewMessageToMainChat,
        messageLimit,
        changeMessageLimit,
        isTriggerScrollDown,
        triggerScrollDown,
        isActiveGeneration,
        changeActiveGeneration,
        isActiveGenerationMainChat,
        changeActiveGenerationMainChat,
        isActiveLoaderMessageGeneration,
        changeActiveLoaderMessageGeneration,
        isActiveLoaderTranslate,
        changeActiveLoaderTranslate,
        idHandleMessage,
        changeIdHandleMessage,
        currentPage,
        changeCurrentPage,
        lastPage,
        changeLastPage,
        foundWord,
        changeSearchWord,
        isActiveSearching,
        changeActiveSearching,
        triggerSaveScrollForPagination,
        clearChatStore, clearMainChat, cleanFullChatStore
    }
})
