<template>
  <div class="tasks-card" @click="addTasksToUser">
    <div class="wrapper-task-title">
      <img :src="card.image" alt="task icon">
      <h3 class="tasks-card__title">{{ card.title }}</h3>
    </div>

    <p class="tasks-card__descr">{{ card.description }}</p>

    <div class="wrapper-loader" v-if="isActiveLoaderStartTask">
      <LoaderSpiner :is-yellow-color="true"></LoaderSpiner>
    </div>
  </div>

  <ShowWordsForTask v-if="isOpenWordList"
                    :task="card"
                    @hiddenBlock="toggleOpenWordsList">
  </ShowWordsForTask>
  <InfoForInterview v-if="isOpenInfoForInterview"
                    :task="card"
                    @hiddenBlock="toggleOpenInfoForInterview">
  </InfoForInterview>
</template>

<script setup>
import taskRequests from "@/mixins/requests/taskRequests";
import ShowWordsForTask from "@/components/modals/ShowWordsForTask";
import InfoForInterview from "@/components/modals/InfoForInterview";
import LoaderSpiner from "@/components/widgets/LoaderSpiner";
import {ref} from "vue";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";

const props = defineProps({
  card: Object,
});
const {taskStart} = taskRequests();
const taskStore = tasksStore();
const {isMoveSlider} = storeToRefs(taskStore);

let isOpenWordList = ref(false);
let isActiveLoaderStartTask = ref(false);
let isOpenInfoForInterview = ref(false);

function addTasksToUser() {
  if (isMoveSlider.value) return;

  if (props.card.type.title === "Тренировка слова" || props.card.type.title === "Игра: загадай слово") {
    toggleOpenWordsList(true);
  } else if (props.card.title === "Симулятор собеседования") {
    toggleOpenInfoForInterview(true);
  } else {
    isActiveLoaderStartTask.value = true;
    taskStart(props.card.id)
        .then(() => isActiveLoaderStartTask.value = false)
  }
}

function toggleOpenWordsList(isOpen) {
  isOpenWordList.value = isOpen;
}

function toggleOpenInfoForInterview(isOpen) {
  isOpenInfoForInterview.value = isOpen;
}
</script>

<style scoped lang="scss">
.tasks-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border-radius: 10px;
  width: 300px;
  padding: 25px 20px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  cursor: pointer;
  transition: all 0.2s;
  color: var(--white);
  background-color: var(--pink);
  user-select: none;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
  }

  .wrapper-task-title {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    img {
      width: 30px;
    }

    .tasks-card__title {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 10px;
      color: var(--yellow);
    }
  }

  .tasks-card__img {
    width: 40px;
    height: 40px;
  }

  .tasks-card__descr {
    font-size: 15px;
    font-weight: 600;
  }

  .info {
    display: flex;
    gap: 10px;

    .count_tasks,
    .hours {
      display: flex;
      align-items: center;
      gap: 3px;
      color: #d1d1d1;

      img {
        width: 25px;
        height: 25px;
      }

      p {
        font-size: 10px;
      }
    }
  }

  .progress {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    color: var(--white);

    .load_line {
      display: flex;
      position: relative;
      width: 100%;
      height: 3px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 40px;

      .done_progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 3px;
        border-radius: 40px;
        background-color: #2ec386;
      }
    }
  }

  .progress_done {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    margin-top: 10px;
    color: var(--white);

    .done {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #2ec386;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .wrapper-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    background-color: rgba(163, 54, 214, 0.6);
  }
}

@media screen and (min-width: 2200px) {
  .tasks-card {
    width: 400px;
    gap: 20px;
    padding: 25px;

    &:hover {
    }

    .wrapper-task-title {

      img {
        width: 40px;
      }

      .tasks-card__title {
        font-size: 30px;
      }
    }

    .tasks-card__img {

    }

    .tasks-card__descr {
      font-size: 20px;
    }

    .info {

      .count_tasks,
      .hours {


        img {

        }

        p {
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .tasks-card {
    width: 200px;
    gap: 5px;
    padding: 20px 15px;

    &:hover {
    }

    .wrapper-task-title {

      img {
        width: 20px;
      }

      .tasks-card__title {
        font-size: 16px;
      }
    }

    .tasks-card__img {

    }

    .tasks-card__descr {
      font-size: 13px;
    }

    .info {

      .count_tasks,
      .hours {


        img {

        }

        p {
        }
      }
    }
  }
}
</style>
