import {defineStore} from "pinia/dist/pinia";
import {ref} from "vue";
import {
    ANSWERS,
    EMPTY_DATA_FOR_ASSESSMENT,
    TEXT_FOR_A1,
    TEXT_FOR_A2,
    TEXT_FOR_B1,
    TEXT_FOR_B2
} from "@/configuration/Assessment";
import storage from "@/mixins/storage";
import {IS_SHOW_ASSESSMENT} from "../../constants";

export const checkLevelStore = defineStore('checkLevel', () => {
    const {setLocalStorage, getLocalStorage} = storage();

    let isOpenAssessmentModal = ref(false);
    let usersAnswers = ref(JSON.parse(JSON.stringify(EMPTY_DATA_FOR_ASSESSMENT)));
    let resultBalls = ref(0);
    let resultText = ref(null);
    let isShowLevelsBlock = ref(false);
    let isShowAssessmentBlock = ref(false);
    let currentAssessmentIndex = ref(-1);
    let isBackInAssessmentBlock = ref(false);
    let isFinishAssessment = ref(false);

    function clearAssessmentStore() {
        usersAnswers.value = JSON.parse(JSON.stringify(EMPTY_DATA_FOR_ASSESSMENT));
        resultBalls.value = 0;
        resultText.value = null;
        isShowLevelsBlock.value = false;
        isShowAssessmentBlock.value = false;
        currentAssessmentIndex.value = -1;
        isBackInAssessmentBlock.value = false;
        isFinishAssessment.value = false;
    }

    function toggleOpenAssessmentModal(isOpen) {
        isOpenAssessmentModal.value = isOpen;
        setLocalStorage(IS_SHOW_ASSESSMENT, isOpenAssessmentModal.value);
    }

    function checkIsShowAssessment() {
        const personData = getLocalStorage(IS_SHOW_ASSESSMENT);
        if (personData !== false) toggleOpenAssessmentModal(true);
    }

    function toggleOpenLevelsBlock(isShow) {
        isShowLevelsBlock.value = isShow;
    }

    function toggleShowAssessmentBlock(isShow) {
        isShowAssessmentBlock.value = isShow;
    }

    function changeCurrentAssessmentIndex(currentIndex) {
        currentAssessmentIndex.value = currentIndex;
    }

    function toggleIsBackInAssessment(isBack) {
        isBackInAssessmentBlock.value = isBack;
    }

    function checkAssessment() {
        for (let i = 0; i <= 12; i++) {
            if (usersAnswers.value[i].data === ANSWERS[i]) {
                resultBalls.value = resultBalls.value + 1;
                usersAnswers.value[i].isCorrectResult = true;
            } else {
                usersAnswers.value[i].isCorrectResult = false;
            }
        }

        if (resultBalls.value === 13) {
            resultText.value = TEXT_FOR_B2;
        } else if (resultBalls.value <= 12 && resultBalls.value >= 9) {
            resultText.value = TEXT_FOR_B1;
        } else if (resultBalls.value <= 8 && resultBalls.value >= 5) {
            resultText.value = TEXT_FOR_A2;
        } else {
            resultText.value = TEXT_FOR_A1;
        }
        currentAssessmentIndex.value = currentAssessmentIndex.value + 1;
        isFinishAssessment.value = true;
    }

    return {
        isOpenAssessmentModal, toggleOpenAssessmentModal, checkIsShowAssessment,
        isShowLevelsBlock, toggleOpenLevelsBlock,
        isShowAssessmentBlock, toggleShowAssessmentBlock,
        currentAssessmentIndex, changeCurrentAssessmentIndex,
        isBackInAssessmentBlock, toggleIsBackInAssessment,
        usersAnswers, checkAssessment,
        resultText,
        isFinishAssessment,
        clearAssessmentStore
    }
})
