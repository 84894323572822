<template>
  <div class="delete-account">
    <h2>Удалить аккаунт</h2>
    <p>Удалив свою учетную запись, ваши тарифы будут потеряны, а все данные,
      связанные с вашей учетной записью, будут безвозвратно утеряны. Это действие необратимо!
    </p>
    <button class="secondary-button" @click="toggleOpenDialog">Удалить аккаунт</button>
  </div>

  <AcceptDeleteAccount v-if="isOpenAcceptDialog" @hiddenBlock="toggleOpenDialog"></AcceptDeleteAccount>
</template>

<script setup>
import {ref} from "vue";
import AcceptDeleteAccount from "@/components/modals/AcceptDeleteAccount";

let isOpenAcceptDialog = ref(false);

function toggleOpenDialog() {
  isOpenAcceptDialog.value = !isOpenAcceptDialog.value;
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.delete-account {
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  gap: 20px;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  p {
    color: var(--dark-pink);
  }

  .secondary-button {
    padding: 5px;
    background-color: var(--red);

    &:hover {
      background-color: var(--light-red);
    }
  }
}

@media screen and (max-width: 1000px) {
  .delete-account {
    max-width: unset;
    min-width: 350px;
  }
}
</style>
