<template>
  <div class="person_message"
       :class="{'animate__animated animate__fast animate__fadeInRight': isActiveShowAnimation}">
    <div class="message"
         :class="{
                    'bg-done': message?.grading === 'success',
                    'bg-reject': message?.grading === 'error',
                    'full-width': message?.audio
                 }">

      <div class="wrapper-audio" v-if="message?.audio" :class="{'wrapper-audio-border': message.message}">
        <WavePlayer :url="message?.audio"></WavePlayer>
      </div>
      <span>{{ message.message }}</span>
    </div>
    <CommentElement v-if="message?.spelling_comment" :comment="message"></CommentElement>
    <div class="loader-for-handle-message" v-if="idHandleMessage === message?.message_id">
      <p class="text">Анализирую ваше сообщение<span class="dots"></span></p>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, toRefs} from "vue";
import CommentElement from "@/components/chat/CommentElement";
import {chatStore} from "@/store/chatStore";
import {storeToRefs} from "pinia/dist/pinia";
import WavePlayer from "@/components/chat/WavePlayer";

const props = defineProps({
  message: Object
})
const {message} = toRefs(props);
const chat = chatStore();
const {idHandleMessage} = storeToRefs(chat);
let isActiveShowAnimation = ref(true);

onMounted(() => {
  setTimeout(() => {
    isActiveShowAnimation.value = false;
  }, 2000)
})
</script>

<style scoped lang="scss">

.person_message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .message {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px;
    padding: 5px 10px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    max-width: 60%;
    background-color: var(--light-gray);

    .wrapper-audio {
      width: 100%;

      &.wrapper-audio-border {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--dark-pink);
      }
    }

    span {
      position: relative;
      cursor: default;
      transition: all 0.2s;

      span.english-word:hover {
        color: var(--red);
      }
    }

    .message-in-main-page {
      white-space: pre-wrap;
    }

    .translate-all-message {
      position: absolute;
      opacity: 0;
      bottom: -7px;
      right: -15px;
      display: flex;
      border-radius: 10px;
      padding: 4px 6px;
      border: 2px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      background-color: var(--yellow);
      transition: all 0.2s;

      img {
        width: 20px;
      }

      &:hover {
        background-color: var(--light-yellow);
      }

      &:active {
        box-shadow: 0 0 1px var(--dark);
        transform: translateY(5px);
      }
    }

    &:hover {
      .translate-all-message {
        opacity: 1;
      }
    }
  }

  .loader-for-handle-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgba(163, 54, 214, 0.6);

    .text {
      display: flex;
      align-items: flex-end;
      color: var(--yellow);
      font-size: 14px;
      margin: 10px 10px 0 0;

      .dots {
        display: flex;
        width: 5px;
        margin-left: 14px;
        margin-bottom: 7px;
        aspect-ratio: 1;
        border-radius: 50%;
        animation: l5 2s infinite linear alternate;
      }

      @keyframes l5 {
        0% {
          box-shadow: 8px 0 #ffd073, -8px 0 #0002;
          background: #ffd073
        }
        33% {
          box-shadow: 8px 0 #0002, -8px 0 #ffd073;
          background: #0002
        }
        66% {
          box-shadow: 8px 0 #ffd073, -8px 0 #0002;
          background: #0002
        }
        100% {
          box-shadow: 8px 0 #0002, -8px 0 #ffd073;
          background: #ffd073
        }
      }
    }
  }

  .bg-done {
    background-color: var(--green);
  }

  .bg-reject {
    background-color: var(--light-red);
  }

  .full-width {
    width: 100%;
  }
}

@media screen and (min-width: 2200px) {
  .person_message {
    .message {
      span {
        font-size: 23px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .person_message {
    .message {
      max-width: 70%;

      span {
        font-size: 13px;
      }
    }
  }
}
</style>
