<template>
  <div class="wrapper-wave-player">
    <button>
      <img @click="pause" v-if="isPlay" src="/img/chart/pause.svg" alt="pause">
      <img @click="play" v-else src="/img/chart/play.svg" alt="play">
    </button>

    <p class="current-time">
      <span>{{ currentMinute }}</span>:
      <span v-if="currentSeconds<10">0</span>
      <span>{{ currentSeconds }}</span>
    </p>

    <div class="wave" ref="soundUser"></div>

    <p class="time">
      <span>{{ minutesAudio }}</span>:
      <span v-if="secondsAudio<10">0</span>
      <span>{{ secondsAudio }}</span>
    </p>
  </div>
</template>

<script setup>
import {onMounted, ref, toRefs} from "vue";
import WaveSurfer from 'wavesurfer.js'

const props = defineProps({
  url: String
})
const {url} = toRefs(props);

const soundUser = ref(null);

let isPlay = ref(false);
let currentMinute = ref(0);
let currentSeconds = ref(0);
let minutesAudio = ref(0);
let secondsAudio = ref(0);
let wavesurfer

onMounted(() => {
  wavesurfer = WaveSurfer.create({
    container: soundUser.value,
    waveColor: 'rgb(200, 0, 200)',
    progressColor: '#671ba1',
    url: url.value,
    height: 50,
    splitChannels: true,
    normalize: true,
    cursorColor: "#671ba1",
    cursorWidth: 2,
    barWidth: 3,
    barGap: 2,
    barRadius: 2,
    barHeight: null,
    barAlign: "",
    minPxPerSec: 1,
    fillParent: true,
    autoplay: false,
    interact: true,
    dragToSeek: true,
    hideScrollbar: false,
    audioRate: 1,
    autoScroll: true,
    autoCenter: true,
    sampleRate: 8000
  })

  wavesurfer.on('finish', () => isPlay.value = false)

  wavesurfer.on('ready', () => {
    minutesAudio.value = Math.floor(wavesurfer.getDuration() / 60);
    secondsAudio.value = Math.round(wavesurfer.getDuration()) % 60;
  });

  wavesurfer.on('timeupdate', (event) => {
    currentMinute.value = Math.floor(event / 60);
    currentSeconds.value = Math.round(event) % 60;
  })
})

function play() {
  isPlay.value = true;
  wavesurfer.play();
}

function pause() {
  isPlay.value = false;
  wavesurfer.pause();
}
</script>

<style scoped lang="scss">
.wrapper-wave-player {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      transition: all 0.2s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .wave {
    width: 100%;
  }

  .time,
  .current-time {
    display: flex;
    color: var(--dark-pink);
    font-weight: 700;
  }
}
</style>
