<template>
  <div class="change-password">
    <h2>Смена пароля</h2>
    <InputElement :title="'oldPassword'"
                  :data="changePasswordData"
                  :validationInput="vPassword$"
                  :type="'text'"
                  :placeholder="'Старый пароль'">
    </InputElement>
    <InputElement :title="'newPassword'"
                  :data="changePasswordData"
                  :validationInput="vPassword$"
                  :type="'text'"
                  :placeholder="'Новый пароль'">
    </InputElement>
    <InputElement :title="'confirmNewPassword'"
                  :data="changePasswordData"
                  :validationInput="vPassword$"
                  :type="'text'"
                  :placeholder="'Повторите новый пароль'">
    </InputElement>

    <button class="secondary-button" @click="changePass">Сменить пароль</button>
  </div>
</template>

<script setup>
import InputElement from "@/components/signin/InputElement";
import {computed, ref} from "vue";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {minLength, sameAs} from "@vuelidate/validators";
import authRequests from "@/mixins/requests/authRequests";

const {changePassword} = authRequests();

let changePasswordData = ref({
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
})

const passwordRef = computed(() => changePasswordData.value.newPassword);
let rulesChangePassword = {
  oldPassword: {required},
  newPassword: {required, minLength: minLength(8)},
  confirmNewPassword: {required, sameAs: sameAs(passwordRef)},
}

const vPassword$ = useVuelidate(rulesChangePassword, changePasswordData.value);

function changePass() {
  vPassword$.value.$validate();

  if (!vPassword$.value.$error) {
    changePassword(changePasswordData.value)
        .then(response => {
          console.log(response)
        })
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.change-password {
  min-width: 350px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  input {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--yellow);
    transition: all 0.2s;

    &::placeholder {
      color: var(--dark);
    }

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .secondary-button {
    padding: 5px;
    background-color: var(--dark-pink);
    color: var(--light-yellow);

    &:hover {
      background-color: var(--pink);
    }
  }
}
</style>
