<template>
  <div class="wrapper-welcome-block">
    <div class="welcome">
      <img class="robot" src="img/robots/робот-05.png" alt="робот">
      <h2>Приветствую, я - НейроРепетитор!</h2>
      <div class="text">
        <p>Самое время заняться английским. Начните с выполнения интересного задания</p>
        <button class="secondary-button" @click="openTask">
          <template v-if="!isActiveTrainTask">
            Выполнить задание
            <img src="img/main/arrow-top-right.svg">
          </template>
          <LoaderCircle :isYellowColor="true" v-else></LoaderCircle>
        </button>
      </div>
    </div>

    <div class="wrapper-iframe">
      <p class="video-title">Что тут можно делать?</p>
      <iframe
          src="https://www.youtube.com/embed/ykTaJrw9GJ4?si=VFy0nledeR_GPp0b&mute=1"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; loop; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

      </iframe>
    </div>
  </div>

  <div class="wrapper-tasks">
    <div class="training-word">
      <p>Тренировка случайного слова</p>
      <button class="secondary-button" @click="openTaskWithWord">
        <template v-if="!isActiveTrainWord">
          Выполнить задание
          <img src="img/main/arrow-top-right.svg">
        </template>
        <LoaderCircle :isYellowColor="true" v-else></LoaderCircle>
      </button>
    </div>

    <div class="dialog">
      <p>Поговорить с НейроРепетитором</p>
      <button class="secondary-button" @click="scrollToElement">
        Перейти к диалогу
        <img src="img/main/arrow-top-right.svg">
      </button>
    </div>
  </div>
</template>

<script setup>
import taskRequests from "@/mixins/requests/taskRequests";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import dictionaryRequests from "@/mixins/requests/dictionaryRequests";
import {dictionaryStore} from "@/store/dictionaryStore";
import LoaderCircle from "@/components/app/LoaderCircle";
import {ref} from "vue";

const {taskStart} = taskRequests();
const {getWordsFromFirstGroup, addWordsToUserDictionary} = dictionaryRequests();
const taskStore = tasksStore();
const {allTasks} = storeToRefs(taskStore);
const dictionary = dictionaryStore();
const {groups} = storeToRefs(dictionary);

// eslint-disable-next-line no-undef
const emit = defineEmits(['triggerChatAnimation']);

let isActiveTrainWord = ref(false);
let isActiveTrainTask = ref(false);

function openTask() {
  if (allTasks.value?.length) {
    isActiveTrainTask.value = true;
    taskStart(allTasks.value[0].id)
        .then(() => isActiveTrainTask.value = false)
  }
}

function openTaskWithWord() {
  if (isActiveTrainWord.value) return;
  isActiveTrainWord.value = true;

  getWordsFromFirstGroup(groups.value[0].id)
      .then(response => {
        if (response.data?.data?.words?.data?.length) {
          const randomId = Math.floor(Math.random() * response.data?.data?.words?.data?.length);
          const taskForWord = allTasks.value.find(task => task.title === 'Игра: загадай слово');
          addWordsToUserDictionary(response.data?.data?.words?.data[randomId].id, true, taskForWord.id);
        }

        setTimeout(() => {
          isActiveTrainWord.value = false;
        }, 2000)
      })
}

function scrollToElement() {
  emit('triggerChatAnimation');

  const dialog = document.getElementById('chatMainPage');
  window.scrollTo({
    top: dialog.offsetTop - 200,
    behavior: "smooth"
  })
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.wrapper-tasks {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 35px;

  .training-word,
  .dialog {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 25px;
    margin-top: 0;
    border-radius: 10px;
    background-color: var(--yellow);
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);

    p {
      font-weight: 700;
      color: var(--dark-pink);
    }

    .secondary-button {
      position: relative;
      width: 200px;
      min-width: 180px;
      height: 40px;
      padding: unset;
      color: var(--light-green);
      background-color: var(--dark-pink);

      img {
        width: 20px;
      }

      &:hover {
        background-color: var(--pink);
      }
    }
  }

  .training-word {
    margin-right: 0;
  }

  .dialog {
    margin-left: 0;
  }
}

.wrapper-welcome-block {
  width: 100%;
  max-width: 1440px;
  display: flex;
  gap: 20px;
  padding: 35px 20px;


  .welcome {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 35px;
    border-radius: 10px;
    background-color: var(--yellow);
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);

    .robot {
      position: absolute;
      top: 0;
      left: 10px;
      width: 140px;
    }

    h2 {
      text-align: center;
      color: var(--dark-pink);
      font-size: 30px;
      font-weight: 800;
      margin-left: 100px;
    }

    .text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-top: 30px;

      p {
        color: var(--dark-pink);
        font-size: 18px;
        font-weight: 800;
      }

      button {
        min-width: 200px;
        height: 40px;
        padding: unset;
        color: var(--light-green);
        background-color: var(--dark-pink);

        img {
          width: 20px;
        }

        &:hover {
          background-color: var(--pink);
        }
      }
    }
  }

  .wrapper-iframe {
    position: relative;
    width: 30%;
    border-radius: 10px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);

    .video-title {
      position: absolute;
      top: -25px;
      font-weight: 700;
      color: var(--dark-pink);
    }

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}

@media screen and (min-width: 2200px) {
  .wrapper-tasks {
    max-width: 1920px;

    .training-word,
    .dialog {

      p {
        font-weight: 700;
        font-size: 25px;
      }

      .secondary-button {
        width: 320px;
        height: 55px;
        font-size: 23px;
      }
    }
  }

  .wrapper-welcome-block {
    max-width: 1920px;

    .welcome {
      .robot {
        width: 160px;
      }

      h2 {
        font-size: 43px;
      }

      .text {
        p {
          font-size: 25px;
        }

        button {
          font-size: 22px;
          width: 300px;
          height: 50px;

          img {
            width: 24px;
          }
        }
      }
    }

    .wrapper-iframe {

      .video-title {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper-tasks {
    flex-direction: column;

    .training-word,
    .dialog {
      margin: 0 20px;
    }
  }

  .welcome {

    .robot {
      left: 0;
    }
  }
}

@media screen and (max-width: 850px) {
  .wrapper-tasks {
    .training-word,
    .dialog {
      .secondary-button {
      }
    }

    .training-word {
    }

    .dialog {
    }
  }

  .wrapper-welcome-block {
    flex-direction: column-reverse;

    .welcome {
      .robot {
      }

      .text {
      }
    }

    .wrapper-iframe {
      width: 100%;
      height: 50vw;

      .video-title {
      }

      iframe {
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .wrapper-tasks {
    .training-word,
    .dialog {
      margin: 0 10px;

      .secondary-button {
        width: 160px;
        min-width: 140px;
        font-size: 12px;

        img {
          width: 15px;
        }
      }
    }
  }

  .wrapper-welcome-block {
    padding: 40px 10px 20px 10px;

    .welcome {
      padding: 15px;

      .robot {
        display: none;
      }

      h2 {
        text-align: center;
        margin: unset;
      }

      .text {
        flex-direction: column;

        p {
          text-align: center;
        }

        .secondary-button {
          width: 160px;
          min-width: 140px;
          font-size: 12px;

          img {
            width: 15px;
          }
        }
      }
    }
  }
}
</style>
