import axios from "axios";
import {API_URL, ERROR_SOME_THING_WRONG, ERROR_TO_MANY_REQUESTS} from '@/../config';
import requestOptions from "@/mixins/prepare-requests/requestOptions";
import {HEADER_PARAMETERS} from "@/../config"
import {dictionaryStore} from "@/store/dictionaryStore";
import {chatStore} from "@/store/chatStore";
import {notificationStore} from "@/store/notificationStore";
import shared from "@/mixins/shared";
import taskRequests from "@/mixins/requests/taskRequests";
import {storeToRefs} from "pinia/dist/pinia";

export default function dictionaryRequests() {
    const dictionary = dictionaryStore();
    const {
        changeWords,
        addMoreWords,
        changePaginationForUserWords,
        deleteWord,
        changeIsSearch,
        changeGroups,
        changeGroupWords,
        addWordsToGroupWords,
        toggleActiveLoader,
        toggleActiveUserWordLoader,
        toggleActiveGroupWordsLoader,
        changeAllPagesWordsInGroup,
        changeCurrentPageWordsInGroup,
        triggerScrollToWords,
        COMPLETED_SORT,
        PROGRESS_SORT
    } = dictionary;
    const {isSearching, searchData} = storeToRefs(dictionary);
    const chat = chatStore();
    const {
        changeSearchWord,
        changeActiveSearching,
        changeActiveLoaderTranslate,
        changeTranslationsMessage,
        changeVoiceMessage
    } = chat;
    const notifications = notificationStore();
    const {openSnackBarDone, openSnackBarReject} = notifications;
    const {prepareForLogout} = shared();
    const {taskStart} = taskRequests();

    function getGroups() {
        axios.get(`${API_URL()}/api/group`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => changeGroups(response.data.data))
            .catch(error => handleError(error))
    }

    function getWordsFromFirstGroup(id) {
        return axios.get(`${API_URL()}/api/group/show?id=${id}&page=1`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                return response
            })
            .catch(error => {
                return error
            })
    }

    function getWordsFromGroup(isPagination, isScrollToWord) {
        if (!dictionary.selectedGroupWords) return;

        toggleActiveGroupWordsLoader(true);
        const currentPage = isPagination ? dictionary.currentPageWordsInGroup + 1 : dictionary.currentPageWordsInGroup;

        axios.get(`${API_URL()}/api/group/show?id=${dictionary.selectedGroupWords.id}&page=${currentPage}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                isPagination ? addWordsToGroupWords(response.data.data.words.data) : changeGroupWords(response.data.data.words.data);
                changeAllPagesWordsInGroup(response.data.data.words.pagination.last_page);
                changeCurrentPageWordsInGroup(response.data.data.words.pagination.current_page);

                setTimeout(() => {
                    toggleActiveGroupWordsLoader(false);

                    if (isScrollToWord) triggerScrollToWords();
                }, 500)
            })
            .catch(error => handleError(error))
    }

    function getAllWords() {
        axios.get(`${API_URL()}/api/word`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                console.log(response)
            })
            .catch(error => handleError(error))
    }

    function getAllUsersWords(isPagination) {
        isPagination ? toggleActiveUserWordLoader(true) : toggleActiveLoader(true);

        let body = {'column': 'word'};

        body = dictionary.filterUserWords.type === COMPLETED_SORT.type ? {
            ...body,
            'is_completed': 1,
            'sort_by': 'desc'
        } : body;
        body = dictionary.sortUserWords.type === PROGRESS_SORT.type ? {
            ...body,
            'column': 'training_count',
            'sort_by': 'desc'
        } : body;
        const currentPage = isPagination ? dictionary.paginationUserWords?.current_page : 0;
        body = currentPage < dictionary.paginationUserWords?.last_page ? {...body, page: currentPage + 1} : body;

        axios.get(`${API_URL()}/api/user/dictionary/words?${new URLSearchParams(body)}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                setTimeout(() => {
                    if (isPagination) {
                        addMoreWords(response.data.data);
                    } else {
                        changeWords(response.data.data);
                    }

                    changePaginationForUserWords(response.data.pagination);
                    toggleActiveLoader(false);
                    toggleActiveUserWordLoader(false);
                }, 1000);
            })
            .catch(error => handleError(error))
    }

    function searchWord(word, isChatsSearch) {
        toggleActiveLoader(true);
        axios.get(`${API_URL()}/api/user/dictionary/words/search?word=${word || searchData.value}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
        })
            .then(response => {
                if (isChatsSearch) {
                    changeSearchWord(response.data.data[0].word);
                    changeActiveSearching(false);
                } else {
                    setTimeout(() => {
                        changeWords(response.data.data);
                        changeIsSearch(true);
                        toggleActiveLoader(false);
                    }, 1000);
                }
            })
            .catch(error => handleError(error))
    }

    function searchFromAllWords(word) {
        changeActiveSearching(true);
        axios.get(`${API_URL()}/api/word/search?word=${word}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
        })
            .then(response => {
                changeSearchWord(response.data.data);
                changeActiveSearching(false);
            })
            .catch(error => {
                changeActiveSearching(false);
            })
    }

    function translateFullMessage(id) {
        changeActiveLoaderTranslate(true);
        axios.post(`${API_URL()}/api/user/tasks/messages/translate`, {user_task_message_id: id}, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
        })
            .then(response => {
                changeActiveLoaderTranslate(false);
                changeTranslationsMessage(response.data.data);
            })
            .catch(error => handleError(error))
    }

    function soundFullMessage(id) {
        changeActiveLoaderTranslate(true);
        axios.post(`${API_URL()}/api/user/tasks/messages/voice`, {user_task_message_id: id}, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
        })
            .then(response => {
                changeActiveLoaderTranslate(false);
                changeVoiceMessage(response.data.data)
            })
            .catch(error => handleError(error))
    }

    function addWordsToUserDictionary(idWord, isOpenTask, taskId) {
        return axios.post(`${API_URL()}/api/user/dictionary/words/add`, {id: idWord}, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                if (isOpenTask) {
                    taskStart(taskId, response.data.data.id);
                } else if (isSearching.value) {
                    openSnackBarDone('Слово добавлено');
                    searchWord();
                } else {
                    openSnackBarDone('Слово добавлено');
                    getAllUsersWords();
                    getWordsFromGroup();
                }
                return response;
            })
            .catch(error => {
                handleError(error);
                return error;
            })
    }

    function addGroupToUser(idGroup) {
        axios.post(`${API_URL()}/api/user/dictionary/groups/add`, {id: idGroup}, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(() => {
                openSnackBarDone('Группа добавлена');
                getGroups();
                getAllUsersWords();
            })
            .catch(error => handleError(error))
    }

    function deleteGroupInUserDictionary(idGroup) {
        axios.delete(`${API_URL()}/api/user/dictionary/groups/delete?id=${idGroup}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(() => {
                openSnackBarDone('Группа удалена');
                getGroups();
                getAllUsersWords();
            })
            .catch(error => handleError(error))
    }

    function requestToDeleteWord(idWord) {
        axios.delete(`${API_URL()}/api/user/dictionary/words/delete?id=${idWord}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
        })
            .then(() => {
                openSnackBarDone('Слово удалено');
                isSearching.value ? searchWord() : deleteWord(idWord);
                if (dictionary.selectedGroupWords?.id) getWordsFromGroup();
            })
            .catch(error => handleError(error))
    }

    function handleError(error) {
        toggleActiveLoader(false);
        toggleActiveUserWordLoader(false);
        changeActiveSearching(false);
        changeActiveLoaderTranslate(false);

        switch (error.response?.status) {
            case 401:
                prepareForLogout();
                break;
            case 500:
            case 404:
                openSnackBarReject(ERROR_SOME_THING_WRONG);
                break;
            case 429:
                openSnackBarReject(ERROR_TO_MANY_REQUESTS);
                break;
            case 503:
            case 422:
            case 409:
                openSnackBarReject(error.response?.data?.message);
        }
    }

    return {
        getGroups,
        getWordsFromFirstGroup,
        getWordsFromGroup,
        getAllWords,
        getAllUsersWords,
        searchWord,
        searchFromAllWords,
        translateFullMessage,
        soundFullMessage,
        addWordsToUserDictionary,
        addGroupToUser,
        deleteGroupInUserDictionary,
        requestToDeleteWord
    }
}
