<template>
  <v-snackbar
      :timeout="20000"
      v-model="isShowInfoStudyWord"
      :absolute="true"
  >
    <div class="study-info">
      <div class="title-modal">
        <img @click="closeInfoModal" src="/img/chart/reject.svg" alt="reject">
      </div>
      <InfoStudyWord></InfoStudyWord>
    </div>
  </v-snackbar>
</template>

<script setup>
import {notificationStore} from "@/store/notificationStore";
import {storeToRefs} from "pinia/dist/pinia";
import InfoStudyWord from "@/components/modals/shared/InfoStudyWord";
import storage from "@/mixins/storage";
import {INFO_STUDY_WORD_STORAGE_KEY} from "../../../constants";
import {onMounted} from "vue";

const notifications = notificationStore();
const {changeShowInfoStudyWord} = notifications;
const {isShowInfoStudyWord} = storeToRefs(notifications);
const {setLocalStorage, getLocalStorage} = storage();

onMounted(() => {
  checkLocalStorage();
})

function checkLocalStorage() {
  if (!getLocalStorage(INFO_STUDY_WORD_STORAGE_KEY)) {
    changeShowInfoStudyWord(true);
  }
}

function closeInfoModal() {
  setLocalStorage(INFO_STUDY_WORD_STORAGE_KEY, 'showed');
  changeShowInfoStudyWord(false);
}
</script>

<style scoped lang="scss">

:deep(.v-snackbar__wrapper) {
  position: fixed;
  left: unset !important;
  transform: unset !important;
  top: 10vh !important;
  bottom: unset !important;
  right: 10px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  padding: 10px;
  background-color: var(--pink) !important;
  opacity: 0.95;

  .v-snackbar__content {
    padding: unset;

    .study-info {

      .title-modal {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;

        img {
          width: 23px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
