<template>
  <div class="price__tariff" :data-aos="!isMiniBlock && !isModalBlock ? 'fade-up' : ''"
       :class="{'mini-block': isMiniBlock, 'is-modal-block': isModalBlock}"
       :data-aos-delay="getTimeDelay()"
       data-aos-duration="500"
       data-aos-once="true">
    <img class="robot" data-aos="fade-down"
         v-if="!isActiveTariff && tariff.name === 'PRO'"
         :data-aos-delay="(index+1) * 150"
         data-aos-duration="500"
         data-aos-once="true"
         src="img/robots/робот-05.png" alt="robot">

    <p class="label-active" v-if="isActiveTariff"
       :class="{'ended': checkActiveTariff() === endedTariffText}">
      {{ checkActiveTariff() }}
    </p>

    <p class="price__tariff-title">{{ tariff.name }}</p>
    <p class="price__tariff-text">{{ tariff.description }}</p>
    <div class="wrapper-price">
      <span class="price__tariff-price">{{ tariff.price }} </span>
      <span class="price__tariff-price2">₽</span>
      /месяц
    </div>
    <div class="price__tariff-buy" v-if="checkShowButton()">
      <button @click="buyTariff"
              :disabled="tariff.name === 'Пробный период'">
        {{ checkButtonName() }}
      </button>
    </div>
    <div class="price__tariff-descr">
      <p>Сообщений в месяц - {{ tariff.message_quota }}</p>
      <p>Заданий в месяц - {{ tariff.task_quota }}</p>
    </div>
  </div>
</template>

<script setup>
import userRequests from "@/mixins/requests/userRequesrs";
import {mainStore} from "@/store/mainStore";
import {storeToRefs} from "pinia/dist/pinia";

// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  tariff: Object,
  index: Number,
  isMiniBlock: Boolean,
  isModalBlock: Boolean,
  isActiveTariff: Boolean
});

const {getPaymentLink} = userRequests();
const main = mainStore();
const {person} = storeToRefs(main);

const endedTariffText = 'Истек';
const activeTariffText = 'Активен';

function buyTariff() {
  getPaymentLink(props.tariff.id);
}

function getTimeDelay() {
  if ((props.index + 1) % 2 === 0) {
    return 200
  } else if ((props.index + 1) % 3 === 0) {
    return 300
  } else {
    return 100
  }
}

function checkActiveTariff() {
  return person.value.plan_ended_at * 1000 < new Date().getTime() ? endedTariffText : activeTariffText;
}

function checkShowButton() {
  if (props.isActiveTariff) {
    return props.tariff.name !== 'Пробный период';
  } else {
    return true
  }
}

function checkButtonName() {
  if (props.isActiveTariff) {
    return props.tariff.name === 'Пробный период' ? 'Активен' : 'Продлить';
  } else {
    return 'Купить'
  }
}
</script>

<style scoped lang="scss">
.price__tariff {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 400px;
  padding: 10px 30px;
  margin-top: 25px;
  border-radius: 10px;
  color: var(--light-yellow);
  border: 2px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);

  .robot {
    position: absolute;
    top: -60px;
    right: -30px;
    display: none;
    width: 150px;
  }

  .label-active {
    position: absolute;
    top: -15px;
    right: 20px;
    padding: 3px 7px;
    border-radius: 10px;
    background-color: var(--hard-green);
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);

    &.ended {
      background-color: var(--light-red);
    }
  }

  .price__tariff-title {
    font-size: 40px;
    font-weight: 800;
  }

  .price__tariff-text {
    font-size: 15px;
    font-weight: 700
  }

  .wrapper-price {
    color: var(--yellow);
    font-weight: 700;

    .price__tariff-price {
      font-size: 32px;
      font-weight: 700;
      color: var(--light-yellow);
    }

    .price__tariff-price2 {
      font-size: 20px;
      font-weight: 700;
      color: var(--light-yellow);
    }
  }

  .price__tariff-buy {

    button {
      border-radius: 10px;
      color: #fff;
      padding: 5px 10px;
      border: 1px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        background-color: var(--yellow);
        color: var(--dark);
      }

      &:active {
        box-shadow: 0 0 1px var(--dark);
        transform: translateY(5px);
      }

      &.active-tariff {
        background-color: var(--hard-green);
        color: var(--dark);

        &:hover {
          background-color: var(--green);
        }
      }
    }
  }

  .price__tariff-descr {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }

  &:first-child {
    color: var(--dark);
    background-color: var(--light-green);

    .wrapper-price {
      color: var(--purple);

      .price__tariff-price {
        color: var(--blue);
      }

      .price__tariff-price2 {
        color: var(--blue);
      }
    }

    .price__tariff-buy {

      button {
        background-color: var(--blue);

        &:hover {
          background-color: var(--light-green);
          color: var(--dark);
        }

        &:active {

        }

        &.active-tariff {
          background-color: var(--hard-green);
          color: var(--dark);

          &:hover {
            background-color: var(--green);
          }
        }
      }
    }

    .price__tariff-descr {
      color: var(--dark);


      button {
        background-color: var(--blue);

        &:hover {
          background-color: var(--light-green);
          color: var(--dark);
        }

        &:active {

        }
      }
    }
  }

  &:nth-child(odd) {
    color: var(--dark);
    background-color: var(--yellow);

    .wrapper-price {
      color: var(--purple);

      .price__tariff-price {
        color: var(--blue);
      }

      .price__tariff-price2 {
        color: var(--blue);
      }
    }

    .price__tariff-buy {

      button {
        background-color: var(--blue);

        &:hover {
          background-color: var(--yellow);
          color: var(--dark);
        }

        &:active {

        }

        &.active-tariff {
          background-color: var(--hard-green);
          color: var(--dark);

          &:hover {
            background-color: var(--green);
          }
        }
      }
    }
  }

  &:nth-child(even) {
    color: var(--dark);
    background-color: var(--light_pink);

    .wrapper-price {
      color: var(--purple);

      .price__tariff-price {
        color: var(--blue);
      }

      .price__tariff-price2 {
        color: var(--blue);
      }
    }

    .price__tariff-buy {

      button {
        background-color: var(--blue);

        &:hover {
          background-color: var(--light-green);
          color: var(--dark);
        }

        &:active {

        }

        &.active-tariff {
          background-color: var(--hard-green);
          color: var(--dark);

          &:hover {
            background-color: var(--green);
          }
        }
      }
    }
  }

  &:last-child {
    color: var(--dark);
    background-color: var(--yellow);

    .robot {
      display: block;
    }

    .wrapper-price {
      color: var(--purple);

      .price__tariff-price {
        color: var(--blue);
      }

      .price__tariff-price2 {
        color: var(--blue);
      }
    }

    .price__tariff-buy {

      button {
        background-color: var(--blue);

        &:hover {
          background-color: var(--yellow);
          color: var(--dark);
        }

        &:active {

        }
      }
    }

    .price__tariff-descr {
      color: var(--dark);
    }
  }
}

.mini-block {
  width: 49%;
  padding: 10px 15px;
  margin-top: unset;
  margin-bottom: 15px;

  .robot {
    display: none !important;
  }

  .price__tariff-title {
    font-size: 30px;
  }
}

.is-modal-block {
  gap: 5px;
  width: 23%;
  padding: 7px 15px;
  margin-top: 25px;

  .robot {
    top: -60px;
    right: -30px;
    width: 150px;
  }

  .price__tariff-title {
    font-size: 28px;
  }

  .price__tariff-text {
    font-size: 14px;
  }

  .wrapper-price {


    .price__tariff-price {
      font-size: 25px;
    }

    .price__tariff-price2 {

    }
  }

  .price__tariff-buy {

    button {
      padding: 2px 10px;
    }
  }

  .price__tariff-descr {
    font-size: 15px;
  }

  &:first-child {

    .wrapper-price {

      .price__tariff-price {
      }

      .price__tariff-price2 {
      }
    }

    .price__tariff-buy {

      button {

      }
    }

    .price__tariff-descr {

      button {
      }
    }
  }

  &:nth-child(odd) {

    .wrapper-price {

      .price__tariff-price {
      }

      .price__tariff-price2 {
      }
    }

    .price__tariff-buy {

      button {

      }
    }
  }

  &:nth-child(even) {

    .wrapper-price {

      .price__tariff-price {
      }

      .price__tariff-price2 {
      }
    }

    .price__tariff-buy {

      button {

        &:hover {

        }
      }
    }
  }

  &:last-child {

    .robot {
    }

    .wrapper-price {

      .price__tariff-price {
      }

      .price__tariff-price2 {
      }
    }

    .price__tariff-buy {

      button {

        &:hover {

        }
      }
    }

    .price__tariff-descr {
    }
  }
}

@media screen and (max-width: 1400px) {
  .price__tariff {
    width: 30%;
  }

  .mini-block {
    width: 49%;
  }
}

@media screen and (max-width: 1300px) {
  .price__tariff {
    width: 40%;
  }

  .mini-block {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .price__tariff {
    width: 80%;
  }

  .mini-block {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .price__tariff {
    width: 100%;

    .robot {
      width: 120px;
    }

    .price__tariff-title {
      font-size: 35px;
    }
  }
}
</style>
