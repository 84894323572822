<template>
  <v-dialog v-model="isOpenInfoEndedTasks" width="auto">
    <div class="ended-tasks">
      <p>{{ textEndedTasksOrMessage }}</p>
      <tariffs-list-view :isModalBlock="true"/>
    </div>
  </v-dialog>
</template>

<script setup>
import {notificationStore} from "@/store/notificationStore";
import TariffsListView from '@/components/widgets/TariffsListView.vue';
import {storeToRefs} from "pinia/dist/pinia";
import userRequests from "@/mixins/requests/userRequesrs";
import {onMounted} from "vue";

const notifications = notificationStore();
const {isOpenInfoEndedTasks, textEndedTasksOrMessage} = storeToRefs(notifications);
const {getPlans} = userRequests();

onMounted(() => {
  getPlans();
})
</script>

<style scoped lang="scss">
@import "src/assets/buttons.scss";

.ended-tasks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  border-radius: 10px;
  padding: 40px 20px;
  border: 2px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light-yellow);
  overflow-x: hidden;
  overflow-y: auto;

  p {
    text-align: center;
    font-size: 23px;
    font-weight: 800;
    color: var(--red);
  }
}

@media screen and (max-width: 600px){
  .ended-tasks {
    padding: unset;
    gap: unset;

    p {
      font-size: 18px;
      margin: 20px 10px 10px 10px;
    }
  }
}
</style>
