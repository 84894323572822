<template>
  <div class="wrapper-images">
    <div class="images-block">
      <p class="not-access-urls" v-if="checkAccessUrls(images)">
        Изображения не доступны
      </p>
      <div class="wrapper-image" v-else v-for="(imageData, index) in sortSelectedImage(images)"
           :class="{'selected-image': imageData.selected}">
        <img :src="imageData.image"
             alt="image">

        <p class="index-image">{{ index + 1 }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  images: Array
})

function sortSelectedImage(images) {
  return images.sort((x, y) => {
    return Number(y.selected) - Number(x.selected);
  })
}

function checkAccessUrls(images) {
  return images.some(image => image.image === null)
}

</script>

<style scoped lang="scss">
.wrapper-images {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;

  .images-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 60%;
    border-radius: 20px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--light-gray);
    overflow: hidden;

    .not-access-urls {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }

    .wrapper-image {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex: auto;
      width: 30%;
      border: 1px solid var(--dark);

      img {
        display: flex;
        flex: 1;
        width: 100%;
      }

      .index-image {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: var(--dark-pink);
        color: var(--yellow);
        border-top-right-radius: 10px;
      }

      &.selected-image {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper-images {
    .images-block {
      width: 80%;

      img {
        &.selected-image {
        }
      }
    }
  }
}
</style>
