<template>
  <label :for="valueInput" :class="{
    'is-selected' : usersAnswers[index].data === valueInput,
    'correctAnswer':  valueInput === ANSWERS[index] && isFinishAssessment,
    'incorrect': usersAnswers[index].data === valueInput && usersAnswers[index]?.isCorrectResult === false && isFinishAssessment
    }">
    <input type="radio" name="fav_language" :id="valueInput" v-model="usersAnswers[index].data" :value="valueInput" :disabled="isFinishAssessment">
    {{ valueInput }}
  </label>
</template>

<script setup>
import {checkLevelStore} from "@/store/checkLevelStore";
import {storeToRefs} from "pinia/dist/pinia";
import {ANSWERS} from "@/configuration/Assessment";

const props = defineProps({
  valueInput: String,
  index: Number
})
const checkLevel = checkLevelStore();
const {usersAnswers, isFinishAssessment} = storeToRefs(checkLevel);
</script>

<style scoped lang="scss">
label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border: 1px solid var(--dark);
  border-radius: 10px;
  color: var(--light-yellow);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--pink);
  transition: all 0.2s;

  &:hover {
    background-color: var(--dark-pink);
  }

  &.correctAnswer {
    background-color: var(--green);
    color: var(--dark);
  }

  &.incorrect {
    background-color: var(--red);
  }
}

.is-selected {
  box-shadow: 0 0 1px var(--dark);
  transform: translateY(5px);
  background-color: var(--dark-pink);
}
</style>
