<template>
  <div class="lesson--page">
    <TaskAndAnswer></TaskAndAnswer>
    <NewChat></NewChat>
  </div>
</template>

<script setup>
import TaskAndAnswer from "@/components/widgets/TaskAndAnswer";
import NewChat from "@/components/widgets/NewChat";
import seo from "@/mixins/seo";
import {LESSON_PAGE_SEO} from "../../seoCongfig";
import {onMounted, onUnmounted, watch} from "vue";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import dictionaryRequests from "@/mixins/requests/dictionaryRequests";

seo(LESSON_PAGE_SEO.title, LESSON_PAGE_SEO.meta);

const taskStore = tasksStore();
const {currentTask} = storeToRefs(taskStore);
const {getAllUsersWords} = dictionaryRequests();

let timeoutId = null;

watch(currentTask, () => {
  seo(`${currentTask.value?.task?.title} | НейроРепетитор`, LESSON_PAGE_SEO.meta);
})

onMounted(()=>{
  window.jivo_onLoadCallback = () => {
    window.jivo_destroy();
  }

  destroyJivoChat();
})

function destroyJivoChat() {
  timeoutId = setTimeout(() => {
    if (!window.jivo_destroy) {
      destroyJivoChat();
      return;
    }

    window.jivo_destroy();
  }, 50);
}


onUnmounted(() => {
  clearTimeout(timeoutId);
  window.jivo_onLoadCallback = null;
  window?.jivo_init();
  getAllUsersWords();
})
</script>

<style scoped lang="scss">
.lesson--page {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  width: 100vw;
  height: calc(100vh - 82px);
  padding: 30px;
  background-color: var(--white);
  overflow: hidden;
}

@media screen and (max-width: 1100px) {
  .lesson--page {
    padding: 10px;
    gap: 10px;
  }
}

@media screen and (max-width: 800px) {
  .lesson--page {
    padding: unset;
  }
}
</style>
