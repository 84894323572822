<template>
  <div class="bot-message"
       :class="{'animate__animated animate__fast animate__fadeInLeft': isActiveShowAnimation}">
    <div class="icon-bot">
      <img :src="router.currentRoute.value.path === LESSON ? currentTask?.character_image : 'img/robots/робот-01.png'">
      <v-tooltip v-if="currentTask?.character_info" activator="parent" location="bottom" class="tooltip-lesson">
        {{ currentTask?.character_info }}
      </v-tooltip>
    </div>

    <div class="message" ref="botMessage">
      <span class="message-in-main-page"
            v-if="router.currentRoute.value.path !== LESSON || message.isHiddenButtons"
            v-html="message.message">
      </span>

      <span v-else v-for="word in message.message"
            :key="word?.id"
            :class="{
              'new-line': word.includes('new-line'),
              'next-line': word.includes('next-line')
            }"
            @click="emit('openOption', $event, word)" v-html="word">
      </span>

      <div class="wrapper-translate"
           ref="wrapperTranslate"
           :class="{'show-translate': isShowTranslate}">
        <p class="translate" ref="translateBlock" v-show="!isActiveTranslateLoader && message.translate">
          {{ message.translate }}
        </p>

        <div class="wrapper-loader-translate-message" v-if="isActiveTranslateLoader">
          <LoaderSpiner :less-margin="true"></LoaderSpiner>
        </div>
      </div>

      <div class="wrapper-voice"
           ref="wrapperSound"
           :class="{'show-voice': isShowSound}">
        <p class="voice" ref="soundBlock" v-show="!isActiveVoiceLoader && message.voice">
          <WavePlayer v-if="message.voice" :url="message?.voice"></WavePlayer>
        </p>

        <div class="wrapper-loader-voice-message" v-if="isActiveVoiceLoader">
          <LoaderSpiner :less-margin="true"></LoaderSpiner>
        </div>
      </div>

      <button class="translate-all-message"
              @click="translateMessage(message.message_id)"
              :class="{'line-button': isLineButtons}"
              v-if="router.currentRoute.value.path === LESSON && !message.isHiddenButtons">
        <img src="img/chart/translate.svg" alt="translate">
        <v-tooltip activator="parent" location="bottom" class="tooltip-lesson">Перевести весь текст</v-tooltip>
      </button>

      <button class="voice-message"
              @click="soundMessage(message.message_id)"
              :class="{'line-button': isLineButtons}"
              v-if="router.currentRoute.value.path === LESSON && !message.isHiddenButtons">
        <img src="img/chart/sound.svg" alt="translate">
        <v-tooltip activator="parent" location="bottom" class="tooltip-lesson">Озвучить текст</v-tooltip>
      </button>
    </div>
  </div>
</template>

<script setup>
import LoaderSpiner from "@/components/widgets/LoaderSpiner";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import {useRouter} from "vue-router/dist/vue-router";
import dictionaryRequests from "@/mixins/requests/dictionaryRequests";
import {onMounted, ref, toRefs, watch} from "vue";
import {LESSON} from "@/configuration/Routers";
import WavePlayer from "@/components/chat/WavePlayer";

const router = useRouter();
const taskStore = tasksStore();
const {currentTask} = storeToRefs(taskStore);
const {translateFullMessage, soundFullMessage} = dictionaryRequests();

const emit = defineEmits(['openOption']);
const props = defineProps({
  message: Object
})
const {message} = toRefs(props);

const botMessage = ref(null);

let isActiveShowAnimation = ref(true);
let isShowTranslate = ref(false);
let isShowSound = ref(false);
let isActiveTranslateLoader = ref(false);
let isActiveVoiceLoader = ref(false);
let isLineButtons = ref(false);
let wrapperTranslate = ref(null);
let wrapperSound = ref(null);
let translateBlock = ref(null);
let soundBlock = ref(null);

onMounted(() => {
  setTimeout(() => {
    isActiveShowAnimation.value = false;
  }, 2000)

  checkHeightMessage();
})

watch(message, () => {
  isActiveTranslateLoader.value = false;
  isActiveVoiceLoader.value = false;
  setTimeout(() => {
    updateHeightMessage();
  })
}, {deep: true})

function translateMessage(id) {
  if (isActiveTranslateLoader.value || isActiveVoiceLoader.value) return;
  isShowTranslate.value = !isShowTranslate.value;
  isShowSound.value = false;
  updateHeightMessage();
  if (message.value.translate) {
    updateHeightMessage();
  } else {
    isActiveTranslateLoader.value = true;
    wrapperTranslate.value.style.height = `${100}px`
    translateFullMessage(id);
  }
}

function soundMessage(id) {
  if (isActiveTranslateLoader.value || isActiveVoiceLoader.value) return;
  isShowSound.value = !isShowSound.value;
  isShowTranslate.value = false;
  updateHeightMessage();

  if (message.value.voice) {
    updateHeightMessage();
  } else {
    isActiveVoiceLoader.value = true;
    wrapperSound.value.style.height = `${100}px`
    soundFullMessage(id);
  }
}

function updateHeightMessage() {
  if (isShowTranslate.value) {
    wrapperTranslate.value.style.height = `${translateBlock.value?.clientHeight + 10}px`;
  } else {
    wrapperTranslate.value.style.height = 0;
  }

  if (isShowSound.value) {
    wrapperSound.value.style.height = `${soundBlock.value?.clientHeight + 10}px`;
  } else {
    wrapperSound.value.style.height = 0;
  }
}

function checkHeightMessage() {
  isLineButtons.value = botMessage.value.offsetHeight < 125;
}
</script>
<style scoped lang="scss">
.bot-message {
  display: flex;
  align-items: flex-end;
  gap: 5px;

  .icon-bot {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 3px solid var(--dark-pink);
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  :deep(.message) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px;
    padding: 5px 10px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    max-width: 60%;
    background-color: var(--light-gray);

    span {
      position: relative;
      display: flex;
      cursor: pointer;
      transition: all 0.2s;

      span.english-word:hover {
        color: var(--red);
      }

      &.new-line {
        width: 100%;
        height: 22px;
      }

      &.next-line {
        width: 100%;
      }
    }

    .message-in-main-page {
      width: 100%;
      white-space: pre-wrap;
      cursor: default;
    }

    .wrapper-translate {
      margin-top: 0;
      padding-top: 0;
      height: 0;
      width: 100%;
      overflow: hidden;
      transition: all 0.4s;

      &.show-translate {
        border-top: 1px solid var(--dark);
        margin-top: 10px;
        padding-top: 10px;
      }

      .translate {
        color: var(--dark-pink);
      }

      .wrapper-loader-translate-message {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .wrapper-voice {
      margin-top: 0;
      padding-top: 0;
      height: 0;
      width: 100%;
      overflow: hidden;
      transition: all 0.4s;

      &.show-voice {
        border-top: 1px solid var(--dark);
        margin-top: 10px;
        padding-top: 10px;
      }

      .voice {
        color: var(--dark-pink);
      }

      .wrapper-loader-voice-message {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .translate-all-message,
    .voice-message {
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 10px;
      right: -25px;
      display: flex;
      border-radius: 10px;
      padding: 4px 6px;
      border: 2px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      background-color: var(--yellow);
      transition: all 0.2s;

      img {
        width: 20px;
      }

      &:hover {
        background-color: var(--light-yellow);
      }

      &:active {
        box-shadow: 0 0 1px var(--dark);
        transform: translateY(5px);
      }
    }

    .translate-all-message {
      &.line-button {
        top: 2px;
        right: -40px;
      }
    }

    .voice-message {
      top: 50px;

      &.line-button {
        top: 2px;
        right: -80px;
      }
    }

    &:hover {
      .translate-all-message,
      .voice-message {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}

@media screen and (min-width: 2200px) {
  .bot-message {

    .icon-bot {
      width: 60px;
      height: 60px;

      img {
      }
    }

    :deep(.message) {

      span {
        align-items: center;
        font-size: 21px;

        span.english-word {
          font-size: 23px;

          &:hover {
            color: var(--red);
          }
        }

        &.new-line {
        }
      }

      .message-in-main-page {
        font-size: 23px;
      }

      .wrapper-translate {

        &.show-translate {

        }

        .translate {
          font-size: 23px;
        }

        .wrapper-loader-translate-message {
        }
      }

      .translate-all-message,
      .voice-message {
        border: 3px solid var(--dark);

        img {
          width: 30px;
        }
      }

      .voice-message {
        top: 70px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .bot-message {

    .icon-bot {

      img {
      }
    }

    :deep(.message) {

      span {

        span.english-word {

          &:hover {
          }
        }

        &.new-line {
        }
      }

      .message-in-main-page {
      }

      .wrapper-translate {

        &.show-translate {

        }

        .translate {
        }

        .wrapper-loader-translate-message {
        }
      }

      .translate-all-message,
      .voice-message {
        opacity: 1;
        z-index: 1;

        img {
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .bot-message {

    .icon-bot {

      img {
      }
    }

    :deep(.message) {
      max-width: 70%;

      span {
        font-size: 13px;

        span.english-word {

          &:hover {
          }
        }

        &.new-line {
        }
      }

      .message-in-main-page {
      }

      .wrapper-translate {

        &.show-translate {

        }

        .translate {
          font-size: 13px;
        }

        .wrapper-loader-translate-message {
        }
      }

      .translate-all-message {


        img {
        }
      }
    }
  }
}
</style>
