<template>
  <v-dialog
      v-model="isOpenDialog"
      width="auto"
  >
    <v-card :class="'rounded-lg'">
      <div class="delete-account">
        <p>Удалить аккаунт? </p>
        <div class="wrapper-button">
          <button class="secondary-button accept" @click="deleteCurrentAccount">Удалить</button>
          <button class="secondary-button reject" @click="hiddenDialog">Отмена</button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref, watch} from "vue";
import userRequests from "@/mixins/requests/userRequesrs";

const {deleteAccount} = userRequests();

// eslint-disable-next-line no-undef
const emit = defineEmits(['hiddenBlock']);

let isOpenDialog = ref(true);

watch(isOpenDialog, () => {
  if (!isOpenDialog.value) emit('hiddenBlock');
})

function hiddenDialog() {
  isOpenDialog.value = false;
}

function deleteCurrentAccount() {
  deleteAccount();
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.delete-account {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: var(--dark-pink);
  padding: 20px;
  border-radius: 20px;
  border: 2px solid var(--dark);

  p {
    color: var(--light-red);
    font-size: 25px;
    font-weight: 700;
  }

  .wrapper-button {
    display: flex;
    gap: 15px;

    button {
      padding: 8px 10px;
    }

    .accept {
      background-color: var(--hard-green);

      &:hover {
        background-color: var(--green);
      }
    }

    .reject {
      background-color: var(--light-red);

      &:hover {
        background-color: var(--red);
      }
    }
  }
}
</style>
