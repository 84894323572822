<template>
  <div class="wrapper-settings-page">
    <div class="settings-page">
      <div class="title-settings">
        <h1>Настройки</h1>
        <p>Здесь вы можете управлять данными своей учетной записи.</p>
      </div>

      <div class="account-settings">
        <NameAndEmailBlock></NameAndEmailBlock>
        <ChangePassword></ChangePassword>
        <TariffBlock></TariffBlock>
<!--        <ChangeLevel :is-show-button="true"></ChangeLevel>-->
        <DeleteAccount></DeleteAccount>
      </div>
    </div>
  </div>
  <MiniChat></MiniChat>
</template>

<script setup>
import NameAndEmailBlock from "@/components/settings/NameAndEmailBlock";
import ChangePassword from "@/components/settings/ChangePassword";
import TariffBlock from "@/components/settings/TariffBlock";
import MiniChat from "@/components/widgets/MiniChat";
import seo from "@/mixins/seo";
import {SETTINGS_PAGE} from "../../seoCongfig";
import {onMounted, onUnmounted} from "vue";
import ChangeLevel from "@/components/settings/ChangeLevel";
import DeleteAccount from "@/components/settings/DeleteAccount";

let timeoutId = null;

seo(SETTINGS_PAGE.title, SETTINGS_PAGE.meta);

onMounted(() => {
  window.jivo_onLoadCallback = () => {
    window.jivo_destroy();
  }

  destroyJivoChat();
})

function destroyJivoChat() {
  timeoutId = setTimeout(() => {
    if (!window.jivo_destroy) {
      destroyJivoChat();
      return;
    }

    window.jivo_destroy();
  }, 50);
}

onUnmounted(() => {
  clearTimeout(timeoutId);
  window.jivo_onLoadCallback = null;
  window?.jivo_init();
})
</script>

<style scoped lang="scss">
.wrapper-settings-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  .settings-page {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px;

    .title-settings {
      margin-top: 20px;

      h1 {
        font-size: 35px;
        font-weight: 800;
        color: var(--dark-pink);
      }

      p {
        font-size: 17px;
        margin-top: 10px;
        font-weight: 700;
      }
    }

    .account-settings {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;
      margin-top: 40px;
    }
  }
}
</style>
