<template>
  <div class="user-agreement">
    <h1>Пользовательское соглашение</h1>

    <p>Настоящий документ «Пользовательское соглашение» представляет собой предложение Якунина
      Владислава Ивановича (далее — «Администрация»), заключить договор на изложенных ниже
      условиях Соглашения.
    </p>

    <h2>1. Общие положения Пользовательского соглашения</h2>
    <p class="paragraph">1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон
      применяются следующие термины и определения:
    </p>

    <ol>
      <li>Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;</li>
      <li>Пользователь — дееспособное физическое лицо, присоединившееся к настоящему
        Соглашению в собственном интересе либо выступающее от имени и в интересах
        представляемого им юридического лица.</li>
      <li>Сайт Администрации/ Сайт — интернет-сайты, размещенные в домене neuro-repetitor.ru и его
        поддоменах.</li>
      <li>Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с использованием
        Платформы.</li>
      <li>Соглашение — настоящее соглашение со всеми дополнениями и изменениями.</li>
    </ol>

    <p class="paragraph">1.2. Использование вами Сервиса любым способом и в любой форме в пределах его
      объявленных функциональных возможностей, включая:</p>

    <ul>
      <li>просмотр размещенных на Сайте материалов;</li>
      <li>регистрация и/или авторизация на Сайте;</li>
      <li>размещение или отображение на Сайте любых материалов, включая но не ограничиваясь
        такими как: тексты, гипертекстовые ссылки, изображения, аудио и видео- файлы,
        сведения и/или иная информация;</li>
    </ul>

    <p class="paragraph">создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и
      438 Гражданского кодекса Российской Федерации.
    </p>

    <p class="paragraph">1.3. Воспользовавшись любой из указанных выше возможностей по использованию Сервиса
      вы подтверждаете, что:
    </p>

    <ol>
      <li>Ознакомились с условиями настоящего Соглашения в полном объеме до начала
        использования Сервиса.</li>
      <li>Принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и
        ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование
        Сервиса. Если вы не согласны с условиями настоящего Соглашения или не имеете права на
        заключение договора на их основе, вам следует незамедлительно прекратить любое
        использование Сервиса.</li>
      <li>Соглашение (в том числе любая из его частей) может быть изменено Администрацией без
        какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента
        ее размещения на Сайте Администрации либо доведения до сведения Пользователя в иной
        удобной форме, если иное не предусмотрено новой редакцией Соглашения. Актуальное
        пользовательское соглашение находится по интернет адресу
        https://neuro-repetitor.ru/user-agreement</li>
      <li>Принимаете политику конфиденциальности, и ознакомлены и согласны с условиями возврата,
        которые доступны по интернет адресам https://app.neuro-repetitor.ru/privacy-policy и
        https://app.neuro-repetitor.ru/refound</li>
    </ol>

    <h2>2. Общие условия пользования Сервисом</h2>
    <p class="paragraph">2.1. Использование функциональных возможностей Сервиса допускается только после
      прохождения Пользователем регистрации и авторизации на Сайте в соответствии с
      установленной Администрацией процедурой.
    </p>
    <p class="paragraph">2.2. Технические, организационные и коммерческие условия использования Сервиса, в том
      числе его функциональных возможностей доводятся до сведения Пользователей путем
      отдельного размещения на Сайте или путем нотификации Пользователей.
    </p>
    <p class="paragraph">2.3. Выбранные Пользователем логин и пароль являются необходимой и достаточной
      информацией для доступа Пользователя на Сайт. Пользователь не имеет права передавать свои
      логин и пароль третьим лицам, несет полную ответственность за их сохранность,
      самостоятельно выбирая способ их хранения.
    </p>
    <p class="paragraph">2.4. При использовании Сервиса запрещается размещать: Материалы противоречащие
      законодательству РФ; Объекты интеллектуальной собственности, копирование которых
      запрещено владельцами оригинала.
    </p>

    <h2>3. Гарантии сторон</h2>
    <p class="paragraph">3.1. Пользователь гарантирует, что любая деятельность, которой он занимается с использованием
      Сервиса не противоречит законодательству РФ и не нарушает прав третьих лиц.
    </p>
    <p class="paragraph">3.2. Администрация не гарантирует бесперебойную работу сервиса.</p>
    <p class="paragraph">3.3. Пользователь гарантирует соблюдать пользовательское соглашение при работе с Сервисом.</p>

    <h2>4. Ограничение ответственности</h2>
    <p class="paragraph">4.1. Администрация не несёт ответственность за любые действия пользователя нарушающие
      законодательство РФ или нарушающие права третьих лиц.
    </p>
    <p class="paragraph">4.2. Администрация не несёт ответственность за всевозможные упущенные выгоды или убытки по любым
      причинам.</p>

    <h2>5. Права сторон</h2>
    <p class="paragraph">5.1. Администрация вправе остановить возможность использования Сервиса пользователем в случае, если
      он нарушил условия и требования пользовательского соглашения, либо же Администрация в праве
      сделать это на своё усмотрение.
    </p>

    <h2>6. Согласие на рассылку</h2>
    <p class="paragraph">6.1. Принимая пользовательское соглашение, пользователь соглашается на рассылку сообщений
      от Администрации на его почтовый ящик.</p>
  </div>
</template>

<script setup>
import seo from "@/mixins/seo";
import {USER_AGREEMENT_PAGE} from "../../seoCongfig";

seo(USER_AGREEMENT_PAGE.title, USER_AGREEMENT_PAGE.meta);
</script>

<style scoped lang="scss">
.user-agreement {
  padding: 50px 40px 20px 40px;

  h1 {
    color: var(--dark-pink);
    margin: 20px 0;
    font-weight: 700;
  }

  h2 {
    margin: 50px 0 15px 0;
  }

  p {
    margin: 15px 0 15px 10px;
  }

  .paragraph {
    margin-left: 30px;
  }

  ul, ol {
    margin-left: 60px;

    li {
      margin: 10px 0;
    }
  }
}


@media screen and (max-width: 400px) {
  .user-agreement {
    padding: 50px 20px 20px 20px;
  }
}
</style>
