<template>
  <div class="second-block">
    <p>{{ titleQuestion }}</p>
    <div class="wrapper-choices">
      <InputRadio v-for="element in questions" :key="element.id"
                  :valueInput="element.title"
                  :index="index">
      </InputRadio>
    </div>
    <NavigationButton :index="index" @goToMenu="emit('goToMenu')"></NavigationButton>
  </div>
</template>

<script setup>
import NavigationButton from "@/components/modals/check-level-modal/questions/NavigationButton";
import InputRadio from "@/components/modals/check-level-modal/questions/InputRadio";

const emit = defineEmits(['goToMenu']);
const props = defineProps({
  titleQuestion: String,
  questions: Array,
  index: Number
})
</script>

<style scoped lang="scss">
@import "src/assets/assessment-block";

.second-block {
  width: 100%;
  max-width: 800px;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  p {
    font-size: 20px;
  }
}
</style>
