
export const API_URL = () => {
    return process.env.VUE_APP_API_URL;
}

export const HEADER_PARAMETERS = {
    content: {
        key: "Content-Type",
        body: 'application/json'
    },
    accept: {
        key: 'Accept',
        body: 'application/json'
    },
    authorization: {
        key: 'Authorization',
        body: 'Bearer'
    }
}

export const ERROR_SOME_THING_WRONG = 'Что-то пошло не так';
export const ERROR_TO_MANY_REQUESTS = 'Слишком много запросов';
