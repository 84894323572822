import axios from "axios";
import {API_URL, ERROR_SOME_THING_WRONG, ERROR_TO_MANY_REQUESTS, HEADER_PARAMETERS} from "../../../config";
import requestOptions from "@/mixins/prepare-requests/requestOptions";
import {chatStore} from "@/store/chatStore";
import {tasksStore} from "@/store/tasksStore";
import shared from "@/mixins/shared";
import {notificationStore} from "@/store/notificationStore";

export default function dialogsRequests() {
    const chat = chatStore()
    const {
        changeMessages,
        changeMainChatMessages,
        changeMessageLimit,
        changeActiveGeneration,
        changeActiveGenerationMainChat,
        changeActiveLoaderMessageGeneration,
        changeIdHandleMessage,
        changeLastPage,
        addNewMessageToMainChat,
        changeLastPersonMessageInMainChat,
        addNextPageMessages,
        addIdToLastPersonMessage,
        updateDataInLastPersonMessage
    } = chat;
    const taskStore = tasksStore();
    const {prepareForLogout} = shared();
    const notifications = notificationStore();
    const {openSnackBarReject, changeOpenInfoEndedTasks} = notifications;

    function getMessages(isPagination) {
        axios.get(`${API_URL()}/api/user/dialog?page=${chat.currentPage}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                if (isPagination) {
                    addNextPageMessages(response.data.data, true);
                } else {
                    changeMainChatMessages(response.data.data);
                    changeMessageLimit(response.data.message_limit);
                    changeLastPage(response.data.pagination.last_page);
                }
            })
            .catch(error => handleError(error))
    }

    function getAllMessagesInTask(id, isPagination) {
        changeActiveLoaderMessageGeneration(true);
        axios.get(`${API_URL()}/api/user/tasks/messages?user_task_id=${id}&page=${chat.currentPage}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                if (isPagination) {
                    addNextPageMessages(response.data.data, false);
                } else {
                    changeLastPage(response.data.pagination.last_page);
                    if (response.data.data.length) {
                        changeMessages(response.data.data, true);
                        changeActiveLoaderMessageGeneration(false);
                    }
                }
            })
            .catch(error => handleError(error))
    }

    function sendMessage(message, audio) {
        changeActiveGenerationMainChat(true);
        const formData = new FormData();
        if (message) formData.append('message', message);
        if (audio) formData.append('audio_file', audio);
        axios.post(`${API_URL()}/api/user/dialog/message/send`, formData, {
            headers: {
                ...requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
                'Content-Type': `multipart/form-data`,
            }
        })
            .then(response => {
                if (audio) changeLastPersonMessageInMainChat(response.data.data)
            })
            .catch(error => handleError(error))
    }

    function sendMessageToTask(message, audio) {
        if (taskStore.currentTask?.task?.title !== 'Игра: изображения') changeActiveGeneration(true);
        const formData = new FormData();
        if (message) formData.append('text', message);
        if (audio) formData.append('audio_file', audio);
        formData.append('user_task_id', taskStore.currentTask.id);

        axios.post(`${API_URL()}/api/user/tasks/messages/send`, formData,
            {
                headers: {
                    ...requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization]),
                    'Content-Type': `multipart/form-data`,
                }
            }
        )
            .then(response => {
                changeIdHandleMessage(response.data[2].message_id);
                addIdToLastPersonMessage(response.data[2].message_id);
                updateDataInLastPersonMessage(response.data[0], response);
            })
            .catch(error => handleError(error))
    }

    function deleteMessages() {
        axios.delete(`${API_URL()}/api/user/dialog`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .catch(error => handleError(error))
    }

    function handleError(error) {
        changeActiveLoaderMessageGeneration(false);
        changeActiveGenerationMainChat(false);
        changeActiveGeneration(false);
        changeIdHandleMessage(null);
        switch (error.response?.status) {
            case 401:
                prepareForLogout();
                break;
            case 500:
            case 404:
                openSnackBarReject(ERROR_SOME_THING_WRONG);
                break;
            case 429:
                openSnackBarReject(ERROR_TO_MANY_REQUESTS);
                break;
            case 503:
            case 422:
            case 409:
                openSnackBarReject(error.response?.data?.message? error.response?.data?.message : error.response?.data?.error);
                break;
            case 402:
                changeOpenInfoEndedTasks(true, error.response?.data?.error)
                break;
        }
    }

    return {
        getMessages,
        getAllMessagesInTask,
        sendMessage,
        sendMessageToTask,
        deleteMessages
    }
}
