<template>
  <div class="wrapper-task-and-answer animate__animated animate__bounceInLeft"
       :class="{'mobile-block': isMobileBlock}">
    <div class="task-and-answer" :class="{'hidden-content': isActiveLoaderMessageGeneration}">
      <div class="wrapper-title">
        <h3><img :src="currentTask?.task?.image" alt="">
          {{ currentTask?.title || currentTask?.task?.title }}
        </h3>

        <button v-if="isMobileBlock" @click="emit('hiddenBlock')">
          закрыть
        </button>
      </div>
      <div class="task">
        <div class="wrapper-theme">
          <p>Тема:</p>
          <p>{{ currentTask?.task?.type?.title }}</p>
        </div>

        <div class="wrapper-task-description">
          <p>Задание:</p>
          <p>{{ currentTask?.task?.description }}</p>
        </div>

        <div class="wrapper-task-description"
             v-if="currentTask?.character_info && currentTask?.task?.title !== 'Игра: изображения'">
          <p>Персонаж:</p>
          <p>{{ currentTask?.character_info }}</p>
        </div>

        <div class="wrapper-task-profession" v-if="currentTask?.properties?.profession">
          <p>Профессия:</p>
          <p>{{ currentTask?.properties?.profession }}</p>
        </div>

        <template v-if="checkShowWord()">
          <div class="wrapper-task-word" v-if="currentTask?.user_word">
            <p v-if="currentTask?.task?.title === 'Игра: загадай слово' || currentTask?.task?.title === 'Игра: отгадай слово'">
              Загаданое слово:</p>
            <p v-else>Изучаемое слово:</p>
            <p>{{ currentTask?.user_word }}</p>
          </div>
        </template>

        <div class="wrapper-description" v-if="currentTask?.task?.description">
          <p>Условия успеха:</p>
          <p>{{ currentTask?.task?.victory_condition }}</p>
        </div>

        <div class="wrapper-condition-reject" v-if="currentTask?.task?.defeat_condition">
          <p>Условия поражения:</p>
          <p>{{ currentTask?.task?.defeat_condition }}</p>
        </div>
      </div>

      <!--      <div class="answer">-->
      <!--        <p>Введите ваш ответ:</p>-->
      <!--        <textarea v-model="answer"-->
      <!--                  @input="autoGrow"-->
      <!--                  ref="textarea"-->
      <!--                  placeholder="Ваш ответ...">-->
      <!--              </textarea>-->
      <!--        <button @click="submitResult">Отправить ответ</button>-->
      <!--      </div>-->

      <div class="wrapper-counter-message"
           v-if="currentTask?.task?.title !== 'Игра: загадай слово' && currentTask?.task?.title !== 'Игра: изображения'">
        <div class="counter">
          <img src="img/chart/done.svg">
          <span>Без ошибок:</span>
          <span>{{ countDoneMessages }}</span>
        </div>
        <div class="counter">
          <img src="img/chart/reject.svg">
          <span>С ошибками:</span>
          <span>{{ countRejectMessages }}</span>
        </div>
      </div>
    </div>

    <div class="wrapper-button-back">
      <button @click="goBack">
        <img src="img/chart/redo.svg" alt="">
        вернуться в меню
      </button>
    </div>
  </div>

  <div class="wrapper-congraduation" v-if="isOpenFinalModal">
    <CongraduationModal :isOpenDialog="isOpenFinalModal" @closeDialog="closeFinalModal"></CongraduationModal>
  </div>

  <RejectModal></RejectModal>
</template>

<script setup>
import RejectModal from "@/components/modals/chat/RejectModal";
import {ref, watch} from "vue";
import {useRouter} from "vue-router/dist/vue-router";
import CongraduationModal from "@/components/modals/chat/CongraduationModal";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import {chatStore} from "@/store/chatStore";

const router = useRouter();
const props = defineProps({
  isMobileBlock: Boolean
})
const emit = defineEmits(['hiddenBlock']);
const taskStore = tasksStore();
const {changeIsOpenDialog} = taskStore;
const {currentTask, isOpenFinalModal} = storeToRefs(taskStore);
const chat = chatStore();
const {messages, isActiveLoaderMessageGeneration} = storeToRefs(chat);

let textarea = ref(null);
let answer = ref('');

let countDoneMessages = ref(0);
let countRejectMessages = ref(0);

watch(messages, () => {
  if (messages.value.length) {
    const countersData = getCountDoneMessages();
    countDoneMessages.value = countersData.done ? countersData.done : 0;
    countRejectMessages.value = countersData.reject ? countersData.reject : 0;
  }
}, {deep: true, immediate: true})

function autoGrow() {
  textarea.value.style.height = "45px";
  textarea.value.style.height = (textarea.value.scrollHeight) + "px";
}

function submitResult() {
  setTimeout(() => {
    changeIsOpenDialog(true)
  }, 1000)
}

function getCountDoneMessages() {
  let countDone = null;
  let countReject = null;

  messages.value.forEach(message => {
    if (!message?.is_bot) {
      if (message?.grading === 'success') {
        countDone++
      } else if (message?.grading === 'error') {
        countReject++
      }
    }
  })

  return {done: countDone, reject: countReject};
}

function closeFinalModal() {
  changeIsOpenDialog(false);
  goBack();
}

function goBack() {
  router.back();
}

function checkShowWord() {
  if (currentTask.value?.task?.title === 'Игра: отгадай слово') {
    return currentTask.value?.status !== 'processing';
  } else {
    return true;
  }
}
</script>

<style scoped lang="scss">
.wrapper-task-and-answer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--dark-pink);
  color: var(--dark);
  padding: 20px;
  opacity: 1;
  transition: all 0.2s;
  overflow-y: auto;

  .task-and-answer {
    opacity: 1;
    transition: all 1.5s;
  }

  .hidden-content {
    opacity: 0;
  }

  .wrapper-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 23px;
      font-weight: 900;
      margin-bottom: 20px;
      color: var(--yellow);

      img {
        width: 30px;
      }
    }

    button {
      background-color: var(--pink);
      color: var(--white);
      padding: 5px;

      &:hover {
        background-color: var(--dark-pink);
        color: var(--white);
      }
    }
  }

  .task {
    border-bottom: 1px solid var(--light-gray);
    margin-bottom: 30px;

    .wrapper-theme,
    .wrapper-task-description,
    .wrapper-task-profession,
    .wrapper-task-word,
    .wrapper-condition-reject,
    .wrapper-description {
      display: flex;
      gap: 10px;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 20px;
      color: var(--yellow);

      p:first-child {
        color: var(--light-gray);
      }
    }

    .wrapper-description {
      p:first-of-type {
        white-space: nowrap;
      }
    }

    .wrapper-condition-reject {
      color: var(--light-red);

      p:first-of-type {
        white-space: nowrap;
      }
    }
  }

  .wrapper-counter-message {
    .counter {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 25px;
      }

      span {
        color: var(--yellow);

        &:first-of-type {
          color: var(--light-gray);
        }
      }

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 20px;

    p {
      color: var(--light-gray);
    }

    textarea {
      width: 100%;
      resize: none;
      overflow: hidden;
      height: 45px;
      min-height: 100px;
      max-height: 300px;
      border: 2px solid var(--dark);
      background-color: var(--light_pink);
      color: var(--dark);
      padding: 0.6em;
      transition: border 0.2s;
      border-radius: 10px;

      &:focus {
        outline: none;
        border: 2px solid var(--dark);
      }

      &::placeholder {
        color: var(--dark);
      }
    }

    button {
      padding: 5px 10px;
      box-shadow: 1px 4px 1px var(--dark);

      &:hover {
        background-color: var(--hard-green);
        color: var(--dark);
      }
    }
  }

  .wrapper-button-back {
    margin-top: 10px;

    button {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      background-color: var(--pink);
      color: var(--white);

      &:hover {
        background-color: var(--dark-pink);
        color: var(--white);
      }

      img {
        width: 30px;
        height: 30px;
        transform: rotateY(180deg);
      }
    }
  }

  button {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--green);
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    transition: all 0.2s;
    cursor: pointer;

    &:active {
      box-shadow: 0 0 1px var(--dark);
      transform: translateY(5px);
    }

    &:hover {
      color: var(--blue);
    }
  }
}

.hidden-content {
  opacity: 0;
}

.wrapper-congraduation {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

@media screen and (min-width: 2200px) {
  .wrapper-task-and-answer {

    .wrapper-title {

      h3 {
        font-size: 30px;
      }

      button {

        &:hover {

        }
      }
    }

    .task {
      .wrapper-theme,
      .wrapper-task-description,
      .wrapper-task-profession,
      .wrapper-task-word,
      .wrapper-condition-reject,
      .wrapper-description {
        font-size: 23px;

        p:first-child {
        }
      }

      .wrapper-condition-reject {

        p:first-of-type {
        }
      }
    }

    .wrapper-counter-message {
      .counter {

        img {
          width: 30px;
        }

        span {
          font-size: 23px;

          &:first-of-type {
          }
        }

        &:first-of-type {
        }
      }
    }

    .answer {

      p {
      }

      textarea {

      }
    }

    .wrapper-button-back {
      font-size: 23px;

      button {

        img {
          width: 33px;
          height: 33px;

        }
      }
    }

    button {

    }
  }
}

@media screen and (max-width: 1100px) {
  .wrapper-task-and-answer {
    padding: 15px;

    h3 {

    }

    .task {
      margin-bottom: 10px;

      .wrapper-theme,
      .wrapper-task-description {
        margin-bottom: 15px;

        p:first-child {

        }
      }
    }

    .answer {
      gap: 10px;

      p {

      }

      textarea {

      }

      button {
        padding: 7px;
        font-size: 13px;
      }
    }

    .wrapper-button-back {
      button {

        img {

        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper-task-and-answer {
    display: none;

    h3 {

    }

    .task {

      .wrapper-theme,
      .wrapper-task-description {

        p:first-child {

        }
      }
    }

    .answer {

      p {

      }

      textarea {

      }

      button {

      }
    }

    .wrapper-button-back {
      button {

        img {

        }
      }
    }
  }

  .mobile-block {
    display: flex;
    position: absolute;
    top: 82px;
    left: 0;
    width: 100vw;
    height: calc(100% - 82px);
    border-radius: unset;
    border: unset;
  }
}
</style>
