<template>
  <div class="refound">
    <h1>Условия возврата</h1>
    <p>Если по каким-либо причинам Вы решили отказаться от приобретенной услуги, то
      можете сделать это в соответствии с Законом РФ «О защите прав потребителей» от
      07.02.1992 № 2300-1.
    </p>

    <p>Почта для заявок на возврат: neuro-repetitor@neuro-repetitor.ru
      Возврату подлежат платежи дата совершения которых не превышает 7 дней с момента
      направления запроса на возврат.
    </p>

    <p>Срок рассмотрения заявки до 7 дней.</p>

    <p>Для более оперативного контакта по возврату - можете написать в службу поддержки</p>
  </div>
</template>

<script setup>
import seo from "@/mixins/seo";
import {REFOUND_PAGE} from "../../seoCongfig";

seo(REFOUND_PAGE.title, REFOUND_PAGE.meta);
</script>

<style scoped lang="scss">
.refound {
  padding: 50px 40px 20px 40px;

  h1 {
    color: var(--dark-pink);
    margin: 20px 0;
    font-weight: 700;
  }

  h2 {
    margin: 50px 0 15px 0;
  }

  p {
    margin: 15px 0 15px 10px;
  }

  .paragraph {
    margin-left: 30px;
  }

  ul {
    margin-left: 60px;

    li {
      margin: 10px 0;
    }
  }
}

@media screen and (max-width: 400px) {
  .refound {
    padding: 50px 20px 20px 20px;
  }
}
</style>
