<template>
  <div class="name-and-email">
    <h2>Аккаунт</h2>
    <div>
      <p>Имя</p>
      <input placeholder="Имя" :value="person?.name" readonly>
    </div>

    <div>
      <p>Почта</p>
      <input placeholder="Почта" :value="person?.email" readonly>
    </div>
  </div>
</template>

<script setup>
import {mainStore} from "@/store/mainStore";
import {storeToRefs} from "pinia/dist/pinia";

const main = mainStore();
const {person} = storeToRefs(main);
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

.name-and-email {
  width: 25%;
  max-width: 350px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  input {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--yellow);
    transition: all 0.2s;

    &::placeholder {
      color: var(--dark);
    }

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .secondary-button {
    padding: 5px;
  }
}

@media screen and (max-width: 650px) {
  .name-and-email {
    max-width: unset;
  }
}
</style>
