<template>
  <main class="signin-wrap">
    <div class="signin">
      <div class="content__signin" :class="{'show-login': isShowLoginBlock}">
        <template v-if="isShowRegistrationSection">
          <RegistrationBlock @openLogin="changeMode"></RegistrationBlock>
        </template>
        <template v-if="isShowLoginSection">
          <LoginBlock @openRegistration="changeMode"></LoginBlock>
        </template>
      </div>
    </div>
  </main>
</template>

<script setup>
import RegistrationBlock from "@/components/signin/RegistrationBlock";
import LoginBlock from "@/components/signin/LoginBlock";
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router/dist/vue-router";
import seo from "@/mixins/seo";
import {SIGNIN_PAGE_SEO} from "../../seoCongfig";
import {AUTH} from "@/configuration/Routers";
import {mainStore} from "@/store/mainStore";
import {storeToRefs} from "pinia/dist/pinia";

seo(SIGNIN_PAGE_SEO.title, SIGNIN_PAGE_SEO.meta);

const main = mainStore();
const {person} = storeToRefs(main);

const route = useRoute();
const router = useRouter();

let isShowLoginBlock = ref(false);
let isShowLoginSection = ref(true);
let isShowRegistrationSection = ref(true)

onMounted(() => {
  handleRoute()
})

watch(route, () => {
  handleRoute()
})

function handleRoute() {
  isShowLoginBlock.value = route.query?.type === 'signin';
  toggleHiddenLoginBlock();

  if (Object.keys(person.value).length) router.push('/');
}

function toggleHiddenLoginBlock() {
  if (isShowLoginBlock.value) {
    isShowLoginSection.value = true
    setTimeout(() => {
      isShowRegistrationSection.value = false;
    }, 200)
  } else {
    isShowRegistrationSection.value = true;
    setTimeout(() => {
      isShowLoginSection.value = false;
    }, 200)
  }

}

function changeMode(arg) {
  arg ? router.push({path: AUTH, query: {type: 'signin'}}) : router.push({path: AUTH})
}
</script>

<style scoped lang="scss">
.signin-wrap {
  width: 100vw;
  height: calc(100vh - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pink);

  .signin {
    position: relative;
    width: 850px;
    height: 470px;
    display: flex;
    border-radius: 10px;
    transition: all 0.2s;

    .content__signin {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      transition: transform 400ms;
      border-radius: 10px;
      border: 2px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
    }

    .show-login {
      transform: rotateX(180deg);
      border: 2px solid var(--dark);
      box-shadow: 1px -4px 1px var(--dark);
    }
  }
}

@media screen and (max-width: 900px) {
  .signin-wrap {

    .signin {
      width: 700px;

      .content__signin {

      }

      .show-login {

      }
    }
  }
}

@media screen and (max-width: 720px) {
  .signin-wrap {

    .signin {
      width: 400px;

      .content__signin {

      }

      .show-login {

      }
    }
  }
}
</style>
