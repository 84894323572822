<template>
  <week-results/>

  <cards-slider-view v-for="category in tasksInCategory" :key="category.id"
                     :title="category.title"
                     :cards="category.tasks"
                     :isUserTasks="false"
  />

  <cards-slider-view
      v-if="userTasks?.length"
      :bgclass="'bg-white'"
      :title="'Ваши задания'"
      :cards="userTasks"
      :isUserTasks="true"
  />
  <MiniChat></MiniChat>
  <ModalInfoAddNewTask v-if="isShowModalAddTask.value"
                       :isShow="isShowModalAddTask"
                       @closeModal="closeModal">
  </ModalInfoAddNewTask>
</template>

<script setup>
import WeekResults from "@/components/widgets/WeekResults.vue";
import CardsSliderView from "@/components/widgets/CardsSliderView.vue";
import MiniChat from "@/components/widgets/MiniChat";
import {onMounted, ref, watch} from "vue";
import taskRequests from "@/mixins/requests/taskRequests";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import ModalInfoAddNewTask from "@/components/modals/ModalInfoAddNewTask";
import seo from "@/mixins/seo";
import {TASK_PAGE_SEO} from "../../seoCongfig";

seo(TASK_PAGE_SEO.title, TASK_PAGE_SEO.meta);
const {getAllCategory, getAllUsersTasks} = taskRequests();
const taskStore = tasksStore();
const {tasksInCategory, userTasks} = storeToRefs(taskStore);

let isShowModalAddTask = ref(false);

onMounted(() => {
  if (!tasksInCategory.value) getAllCategory();
  if (!userTasks.value) getAllUsersTasks();
})

watch(userTasks, () => {
  if (!userTasks.value?.length) {
    isShowModalAddTask.value = true;
  }
})

function closeModal() {
  isShowModalAddTask.value = false;
}
</script>

<style scoped lang="scss">
</style>
