<template>
  <v-snackbar
      :timeout="5000"
      v-model="isShowModal"
      :absolute="true"
  >
    <div class="info-add-task">
      <div class="title-modal">
        <img @click="closeModal" src="/img/chart/reject.svg" alt="reject">
      </div>
      <p>Выберите задание и оно будет открыто и появится в ваших заданиях</p>

    </div>
  </v-snackbar>
</template>

<script setup>
import {notificationStore} from "@/store/notificationStore";
import {storeToRefs} from "pinia/dist/pinia";
import {onMounted, ref, toRefs, watch} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  isShow: Boolean
})
const {isShow} = toRefs(props);

const notifications = notificationStore();
// eslint-disable-next-line no-unused-vars
const {isShowInfoStudyWord} = storeToRefs(notifications);
// eslint-disable-next-line no-undef
const emit = defineEmits(['closeModal']);

let isShowModal = ref(false);

onMounted(() => {
  isShowModal.value = isShow.value;
})

watch(isShow, () => {
  isShowModal.value = isShow.value;
})

function closeModal() {
  emit('closeModal')
}
</script>

<style scoped lang="scss">

:deep(.v-snackbar__wrapper) {
  position: fixed;
  left: unset !important;
  transform: unset !important;
  top: 10vh !important;
  bottom: unset !important;
  right: 10px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  padding: 10px;
  background-color: var(--yellow) !important;
  opacity: 0.95;

  .v-snackbar__content {
    padding: unset;

    .info-add-task {

      .title-modal {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 5px;

        img {
          width: 23px;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      p {
        width: 300px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        color: var(--dark-pink);
      }
    }
  }
}
</style>
