import {API_URL} from "../../../config";
import userRequests from "@/mixins/requests/userRequesrs";
import {mainStore} from "@/store/mainStore";

export default function socialsAuthRequests() {
    const main = mainStore();
    const {changePerson} = main;
    const {getUser} = userRequests();

    function authVK(isRegistration) {
        let popup = window.open(`${API_URL()}/auth/vk`, '_blank', generateParams())
        const currentURL = getPopupLocation(popup);
        checkUrlChange(popup, currentURL, '', isRegistration, 'login/vk');
    }

    function authGoogle(isRegistration) {
        let popup = window.open(`${API_URL()}/auth/google`, '_blank', generateParams())
        const currentURL = getPopupLocation(popup);
        checkUrlChange(popup, currentURL, '', isRegistration, 'login/google');
    }

    function authYandex(isRegistration) {
        let popup = window.open(`${API_URL()}/auth/yandex`, '_blank', generateParams())
        const currentURL = getPopupLocation(popup);
        checkUrlChange(popup, currentURL, '', isRegistration, 'login/yandex');
    }

    function generateParams() {
        const width = window.document.body.clientWidth * 0.6;
        const height = screen.height * 0.6;
        const left = window.document.body.clientWidth / 2 - width / 2;
        const top = screen.height / 2 - height / 2;
        return `width=${width},height=${height},toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=${left},top=${top}`;
    }

    let checkUrlTimeout;

    function checkUrlChange(popup, currentUrl, oldUrl, isRegistration, partOfUrl) {
        if (checkUrlTimeout) clearTimeout(checkUrlTimeout);
        let currentOldUrl = oldUrl;
        if (currentUrl && currentUrl !== oldUrl) {
            currentOldUrl = currentUrl;
            if (currentUrl.includes(partOfUrl)) {
                const regexp = new RegExp(/token=([^&]*)/, 'gmi');
                const result = regexp.exec(currentUrl);
                const token = result?.length ? result[1] : null;
                if (token) {
                    changePerson({ token: token});
                    getUser(true);
                    clearTimeout(checkUrlTimeout);
                    popup.close();
                    return;
                }
            }
        }

        currentOldUrl = getPopupLocation(popup);
        checkUrlTimeout = window.setTimeout(() => {
            const newUrl = getPopupLocation(popup);
            if (newUrl !== undefined) {
                checkUrlChange(popup, newUrl, currentOldUrl, isRegistration, partOfUrl);
            }
        }, 1000);
    }

    function getPopupLocation(popup) {
        try {
            return popup.location.href;
        } catch {
            return ''
        }
    }

    return {
        authVK,
        authGoogle,
        authYandex
    }
}
