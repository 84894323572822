<template>
  <v-dialog
      v-model="isOpenDialog"
      width="auto"
  >
    <v-card :class="'rounded-lg'">
      <div class="word-list-dialog">
        <h3>Выберите слово для тренировки</h3>

        <div class="search-block">
          <v-text-field
              v-model="searchData"
              density="compact"
              variant="solo"
              label="Поиск слов"
              append-inner-icon="mdi-magnify"
              single-line
              hide-details
              @click:append-inner="findWord"
              @keydown.enter="findWord"
              @update:modelValue="debounceTime"
          ></v-text-field>
        </div>

        <div class="wrapper-words" v-if="words.length">
          <button v-for="word in words" :key="word.id" @click="openTask(word)">
            {{ word?.word?.word }}
            <v-tooltip activator="parent" location="bottom" class="tooltip-for-words">{{
                word?.word?.translation
              }}
            </v-tooltip>
          </button>
        </div>
        <p class="have-not-words" v-if="!words.length && !isActiveLoading && !searchData.trim()">
          У вас пока нет слов, найдите нужное вам слово или перейдите в словарь, что бы добавить новые слова.
        </p>
        <p class="have-not-words" v-if="!words.length && !isActiveLoading && searchData.trim()">
          Слово не найдено
        </p>


        <div class="wrapper-loader" v-if="!words.length && isActiveLoading">
          <LoaderSpiner></LoaderSpiner>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import LoaderSpiner from "@/components/widgets/LoaderSpiner";
import {onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import dictionaryRequests from "@/mixins/requests/dictionaryRequests";
import {dictionaryStore} from "@/store/dictionaryStore";
import {storeToRefs} from "pinia/dist/pinia";
import taskRequests from "@/mixins/requests/taskRequests";

const {searchWord, getAllUsersWords} = dictionaryRequests();
const {addWordsToUserDictionary} = dictionaryRequests();
const dictionary = dictionaryStore();
const {words, isActiveLoading, searchData} = storeToRefs(dictionary);
const {clearForSearching} = dictionary;
const {taskStart} = taskRequests();
// eslint-disable-next-line
const emit = defineEmits(['hiddenBlock']);
// eslint-disable-next-line no-undef
const props = defineProps({
  task: Object
})
const {task} = toRefs(props);

let isOpenDialog = ref(true);
let debounceTimeout = null;

onMounted(() => {
  clearSearchedWords();
})

watch(isOpenDialog, () => {
  if (!isOpenDialog.value) emit('hiddenBlock', false);
})

function openTask(word) {
  if (word?.word?.in_dictionary) {
    const wordId = word?.user_word_id ? word?.user_word_id : word?.id;
    taskStart(task.value.id, wordId);
  } else {
    addWordsToUserDictionary(word?.word?.id, true, task.value.id);
  }

  clearSearchedWords();
}

function debounceTime() {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  debounceTimeout = setTimeout(() => {
    findWord();
  }, 1000);
}

// eslint-disable-next-line no-unused-vars
let lastSearch = null;
function findWord() {
  if (!searchData.value.trim()) {
    if (lastSearch)clearSearchedWords();
    return
  }

  clearForSearching();
  if (searchData.value) {
    searchWord(searchData.value);
    lastSearch = searchData.value;
  } else {
    clearSearchedWords();
  }
}

function clearSearchedWords() {
  searchData.value = '';
  clearForSearching();
  getAllUsersWords();
}

onUnmounted(() => {
  clearSearchedWords();
})
</script>

<style lang="scss">
.tooltip-for-words.v-tooltip > .v-overlay__content {
  display: flex;
  background-color: var(--yellow) !important;
  opacity: 0.9;
  color: var(--blue) !important;
  border-radius: 10px;
  border: 1px solid var(--dark);
}
</style>

<style scoped lang="scss">

:deep(.v-card) {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}

:deep(.v-overlay__content) {
  max-height: unset !important;
}

.word-list-dialog {
  width: 600px;
  max-height: 70vh;
  border-radius: 20px;
  background-color: var(--light_pink);
  padding: 25px 20px;
  border: 1px solid var(--dark);

  h3 {
    color: var(--dark-pink);
    font-weight: 800;
    font-size: 20px;
  }

  .search-block {
    width: 50%;
    display: flex;
    gap: 10px;
    margin-top: 15px;

    :deep(.v-input) {

      .v-input__control {
        border-radius: 10px;
        border: 2px solid var(--dark);
        box-shadow: 1px 4px 1px var(--dark);

        .v-field {
          border-radius: 8px;
          background-color: var(--yellow);

          .v-field__input {
            padding-bottom: unset;
            padding-top: unset;
          }
        }
      }
    }
  }

  .wrapper-words {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;

    button {
      color: var(--yellow);
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      background-color: var(--pink);
      transition: all 0.2s;

      &:active {
        box-shadow: 0 0 1px var(--dark);
        transform: translateY(4px);
      }

      &:hover {
        background-color: var(--dark-pink);
      }
    }
  }

  .have-not-words {
    color: var(--dark-pink);
    font-size: 17px;
    font-weight: 800;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--light_pink);
    border: 2px solid var(--dark-pink);
    margin-top: 20px;
  }

  .wrapper-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .word-list-dialog {
    width: 100%;
    max-width: 700px;
    max-height: 70vh;
    padding: 25px 15px;

    h3 {
      text-align: center;
    }

    .wrapper-words {
      gap: 15px;
    }
  }
}
</style>
