export const MAIN_PAGE_SEO = {
    title: 'Нейросеть для изучения английского | НейроРепетитор',
    meta: [{ name: 'description', content: 'Главная страница'}]
}

export const TASK_PAGE_SEO = {
    title: 'Задания | НейроРепетитор',
    meta: [{ name: 'description', content: 'Здесь вы сможете найти список задани а так же ваши задания'}]
}

export const DICTIONARY_PAGE_SEO = {
    title: 'Словарь | НейроРепетитор',
    meta: [{ name: 'description', content: 'Здесь вы сможете найти список ваших слов и список слов по группам'}]
}

export const PROGRESS_PAGE_SEO = {
    title: 'Прогресс | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы сможете увидеть вашу статистику'}]
}

export const TARIFF_PAGE_SEO = {
    title: 'Tарифы | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы сможете увидеть все тарифы'}]
}

export const SIGNIN_PAGE_SEO = {
    title: 'Авторизация | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы сможете войти в аккаунт или зарегестрироваться'}]
}

export const PAYMENT_PAGE_SEO = {
    title: 'Платежи | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы сможете увидить вашу историю платежей и ваш актуальный тариф'}]
}

export const CALLBACK_PAGE_SEO = {
    title: 'Обратная связь | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы сможете запросить обратную связи или связаться с нами через соц сети'}]
}

export const LESSON_PAGE_SEO = {
    title: 'Задание | НейроРепетитор',
    meta: [{ name: 'description', content: 'Это страница задания'}]
}

export const SETTINGS_PAGE = {
    title: 'Настройки | НейроРепетитор',
    meta: [{ name: 'description', content: 'Здесь вы можете управлять данными своей учетной записи.'}]
}

export const USER_AGREEMENT_PAGE = {
    title: 'Пользовательское соглашение | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы можете прочитать пользовательское соглашение.'}]
}

export const REFOUND_PAGE = {
    title: 'Условия возврата | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы можете прочитать об условиях возврата.'}]
}

export const PRIVACY_POLICY_PAGE = {
    title: 'Политика в отношении обработки персональных данных | НейроРепетитор',
    meta: [{ name: 'description', content: 'На этой странице вы можете прочитать об политике в отношении обработки персональных данных.'}]
}
