<template>
  <div class="nav-information">
    <button v-for="(item, index) in ASSESSMENT_DATA.length"
            :class="{
      'selected': index === currentAssessmentIndex,
      'answered': usersAnswers[index]?.data,
      'isDone':  usersAnswers[index]?.isCorrectResult === true,
      'isReject': usersAnswers[index]?.isCorrectResult === false
    }"
            @click="selectCurrentQuestion(index)">
      {{ item }}
    </button>
    <button v-if="isFinishAssessment" class="result-block" @click="selectCurrentQuestion(ASSESSMENT_DATA.length)"
            :class="{ 'selected': ASSESSMENT_DATA.length === currentAssessmentIndex}">
      Результат
    </button>
  </div>
</template>

<script setup>
import {ASSESSMENT_DATA} from "@/configuration/Assessment";
import {checkLevelStore} from "@/store/checkLevelStore";
import {storeToRefs} from "pinia/dist/pinia";

const checkLevel = checkLevelStore();
const {changeCurrentAssessmentIndex, toggleIsBackInAssessment} = checkLevel;
const {currentAssessmentIndex, usersAnswers, isFinishAssessment} = storeToRefs(checkLevel);

function selectCurrentQuestion(index) {
  toggleIsBackInAssessment(currentAssessmentIndex.value > index);
  changeCurrentAssessmentIndex(index);
}
</script>

<style scoped lang="scss">
.nav-information {
  display: flex;
  gap: 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--pink);
    color: var(--light-yellow);
    transition: all 0.2s;

    &.selected {
      box-shadow: 0 0 1px var(--dark);
      transform: translateY(5px);
      background-color: var(--dark-pink);
    }

    &.answered {
      background-color: var(--yellow);
      color: var(--dark-pink);
    }

    &.isDone {
      background-color: var(--green);
      color: var(--dark);
    }

    &.isReject {
      background-color: var(--red);
      color: var(--light-yellow);
    }

    &.result-block {
      width: unset;
      border-radius: 10px;
      padding: 0 10px;
    }
  }
}
</style>
