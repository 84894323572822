<template>
  <div class="task">
    <p>{{ task.title }}</p>
    <button @click="selectedTask">
      <template v-if="!isActiveTask">
        Начать
      </template>
      <LoaderCircle :isYellowColor="true" v-else></LoaderCircle>
    </button>
  </div>
</template>

<script setup>
import LoaderCircle from "@/components/app/LoaderCircle";
import {ref} from "vue";

const notStartLoaderForThisTasks = ['Симулятор собеседования' , 'Игра: загадай слово']
const emit = defineEmits(['selectTask']);
const props = defineProps({
  task: Object
})

let isActiveTask = ref(false);

function selectedTask() {
  if (!notStartLoaderForThisTasks.includes(props.task.title)) isActiveTask.value = true;
  emit('selectTask', props.task);
}
</script>

<style scoped lang="scss">
.task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid var(--dark-pink);
  background-color: var(--light_pink);
  transition: all 0.2s;

  p {
    color: var(--dark-pink);
    font-weight: 800;
  }

  button {
    width: 75px;
    height: 35px;
    border-radius: 10px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    transition: all 0.2s;
    color: var(--light-yellow);
    background-color: var(--dark-pink);

    &:hover {
      background-color: var(--pink);
    }

    &:active {
      box-shadow: 0 0 1px var(--dark);
      transform: translateY(5px);
    }
  }
}

@media screen and (min-width: 2200px) {
  .task {
    p {
      font-size: 23px;
    }

    button {
      font-size: 20px;
    }
  }
}
</style>
