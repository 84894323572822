import axios from 'axios';
import {ERROR_SOME_THING_WRONG, ERROR_TO_MANY_REQUESTS, HEADER_PARAMETERS, API_URL} from '@/../config';
import {mainStore} from '@/store/mainStore';
import {ACCOUNT_STORAGE_KEY} from "../../../constants";
import storage from "@/mixins/storage";
import {notificationStore} from "@/store/notificationStore";
import userRequests from "@/mixins/requests/userRequesrs";
import requestOptions from "@/mixins/prepare-requests/requestOptions";
import {useRouter} from "vue-router/dist/vue-router";
import websocets from "@/mixins/requests/websocets";

export default function authRequests() {
    const router = useRouter();
    const main = mainStore();
    const {changePerson} = main;
    const {getLocalStorage} = storage();
    const notifications = notificationStore();
    const {openSnackBarDone, openSnackBarReject} = notifications;
    const {getUser} = userRequests();
    const {addWebsocet} = websocets();

    function checkLocalStorage() {
        const personData = getLocalStorage(ACCOUNT_STORAGE_KEY);

        if (personData?.id) {
            changePerson(personData);
            getUser();
            addWebsocet();
        }
    }

    function registration(data) {
        return axios.post(`${API_URL()}/api/auth/register`, data)
            .then(response => {
                openSnackBarDone('Успешная регистрация');
                changePerson({...response.data.data.user, token: response.data.data.access_token});
                getUser();
                addWebsocet();
                return response
            })
            .catch(error => {
                handleError(error);
                return error;
            })
    }

    function login(data) {
        return axios.post(`${API_URL()}/api/auth/login`, data)
            .then(response => {
                openSnackBarDone('Успешный вход');
                changePerson({...response.data.data.user, token: response.data.data.access_token});
                getUser();
                addWebsocet();
                return response
            })
            .catch(error => {
                handleError(error);
                return error;
            })
    }

    function changePassword(data) {
        return axios.post(`${API_URL()}/api/auth/password/change`,
            {
                old_password: data.oldPassword,
                new_password: data.newPassword,
            },
            {
                headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
            })
            .then(response => {
                openSnackBarDone(response?.data?.data);
                return response
            })
            .catch(error => {
                openSnackBarReject(error.response.data.error);
                return error;
            })
    }

    function sendEmailForResetPassword(email) {
        return axios.post(`${API_URL()}/api/auth/password/forgot`, {email})
            .then(response => {
                openSnackBarDone('Новый пароль отправлен на вашу электронную почту!');
                return response;
            })
            .catch(error => {
                openSnackBarReject('Что-то пошло не так');
                return error;
            })
    }

    function sendMessageToEmail() {
        return axios.post(`${API_URL()}/api/auth/email/resend`, {}, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept, HEADER_PARAMETERS.authorization])
        })
            .then(response => {
                return response
            })
            .catch(error => {
                handleError(error);
                return error;
            })
    }

    function acceptMail(userId, timestamp, expires, signature) {
        return axios.get(`${API_URL()}/api/auth/email/verify/${userId}/${timestamp}/?expires=${expires}&signature=${signature}`, {
            headers: requestOptions([HEADER_PARAMETERS.content, HEADER_PARAMETERS.accept])
        })
            .then(() => {
                openSnackBarDone('Почта подтвержена');
                router.push('/');
            })
            .catch(error => handleError(error))
    }

    function handleError(error) {
        switch (error.response?.status) {
            case 500:
            case 404:
                openSnackBarReject(ERROR_SOME_THING_WRONG);
                break;
            case 429:
                openSnackBarReject(ERROR_TO_MANY_REQUESTS);
                break;
        }
    }

    return {
        checkLocalStorage,
        registration,
        login,
        changePassword,
        sendEmailForResetPassword,
        sendMessageToEmail,
        acceptMail
    };
}
