<template>
  <v-dialog
      v-model="isOpenDialog"
      width="auto"
  >
    <v-card :class="'rounded-lg'">
      <div class="info-for-interview-modal">
        <p>Напишите должность на какую хотите пройти собеседование.</p>
        <!--        <textarea v-model="textInfo" class="input-info"-->
        <!--                  placeholder="Введите детальную информацию о теме интервью">-->
        <!--        </textarea>-->

        <input v-model="textInfo" @keydown.enter="startTaskInterview" class="input-info"
               placeholder="Напишите должность">

        <button class="secondary-button" @click="startTaskInterview">
          <template v-if="!isActiveTask">
            Начать задание
          </template>
          <LoaderCircle :isYellowColor="true" v-else></LoaderCircle>
        </button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref, toRefs, watch} from "vue";
import taskRequests from "@/mixins/requests/taskRequests";
import LoaderCircle from "@/components/app/LoaderCircle";

const emit = defineEmits(['hiddenBlock']);
const props = defineProps({
  task: Object
})
const {task} = toRefs(props);
const {taskStart} = taskRequests();

let isOpenDialog = ref(true);
let textInfo = ref('');
let isActiveTask = ref(false);

watch(isOpenDialog, () => {
  if (!isOpenDialog.value) emit('hiddenBlock', false);
})

function startTaskInterview() {
  if (textInfo.value) {
    isActiveTask.value = true;
    taskStart(task.value.id, null, textInfo.value);
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/buttons";

:deep(.v-card) {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}

:deep(.v-overlay__content) {
  max-height: unset !important;
}

.info-for-interview-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  max-height: 70vh;
  border-radius: 20px;
  background-color: var(--light_pink);
  padding: 25px 20px;
  border: 1px solid var(--dark);

  .input-info {
    width: 100%;
    height: 100%;
    //min-height: 150px;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid var(--dark);
    box-shadow: 1px 4px 1px var(--dark);
    background-color: var(--pink);
    color: var(--white);
    resize: none;
    outline: none;

    &::placeholder {
      color: var(--light_pink);
    }
  }

  .secondary-button {
    width: 150px;
    height: 45px;
    padding: unset;
    background-color: var(--dark-pink);
    color: var(--white);

    &:hover {
      background-color: var(--pink);
    }
  }
}
</style>
