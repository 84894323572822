<template>
  <div class="wrapper-dictionary-page">
    <DictView/>
    <ThemsView/>
  </div>
  <MiniChat></MiniChat>
</template>

<script setup>
import DictView from '@/components/widgets/DictView.vue';
import ThemsView from '@/components/widgets/ThemsView.vue';
import MiniChat from "@/components/widgets/MiniChat";
import dictionaryRequests from "@/mixins/requests/dictionaryRequests";
import {onMounted} from "vue";
import {dictionaryStore} from "@/store/dictionaryStore";
import taskRequests from "@/mixins/requests/taskRequests";
import {tasksStore} from "@/store/tasksStore";
import {storeToRefs} from "pinia/dist/pinia";
import seo from "@/mixins/seo";
import {DICTIONARY_PAGE_SEO} from "../../seoCongfig";

seo(DICTIONARY_PAGE_SEO.title, DICTIONARY_PAGE_SEO.meta);
const {getGroups, getAllUsersWords} = dictionaryRequests();
const {getAllTasks} = taskRequests()
const taskStore = tasksStore();
const {allTasks} = storeToRefs(taskStore);
const dictionary = dictionaryStore();
const {groups, words} = storeToRefs(dictionary)

onMounted(() => {
  if (!allTasks.value) getAllTasks();
  if (!groups.value.length) getGroups();
  if (!words.value.length) getAllUsersWords();
})
</script>

<style scoped lang="scss">
.wrapper-dictionary-page {
  height: 100%;
  min-height: 100vh;
  background-color: var(--white);
}
</style>
