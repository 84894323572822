<template>
  <div class="ask-check-or-setup-level">
    <Transition :name="getSlideName">
      <div class="wrapper-change-level" v-if="isShowLevelsBlock">
        <ChangeLevel :is-show-button="false"></ChangeLevel>
        <button class="secondary-button" @click="toggleShowMainBlock()">
          <img src="img/chart/redo.svg" alt="redo"> назад
        </button>
      </div>
    </Transition>

    <Transition :name="getSlideName">
      <div class="wrapper-preview-block" v-if="!isShowLevelsBlock">
        <h2>
          Привет, помоги нейрорепетитору подобрать для тебя подходящие задания, пройди тест или укажи свой уровень
          английского
        </h2>

        <div class="wrapper-blocks">
          <div class="know-level">
            <p>Я знаю свой уровень.</p>
            <button class="secondary-button" @click="toggleShowMainBlock()">Указать уровень</button>
          </div>

          <div class="check-level">
            <p>Хочу пройти тест на уровень языка</p>
            <button class="secondary-button" @click="emit('openAssessmentBlock')">Пройти тест</button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import {checkLevelStore} from "@/store/checkLevelStore";
import {storeToRefs} from "pinia/dist/pinia";
import ChangeLevel from "@/components/settings/ChangeLevel";
import {computed, ref} from "vue";

// eslint-disable-next-line no-undef
const emit = defineEmits(['openAssessmentBlock']);
const checkLevel = checkLevelStore();
const {toggleOpenLevelsBlock} = checkLevel;
const {isShowLevelsBlock} = storeToRefs(checkLevel);
const getSlideName = computed(() => isBack.value ? 'slide-from-right' : 'slide-from-left');

let isBack = ref(true);

function toggleShowMainBlock() {
  isBack.value = !isBack.value;
  toggleOpenLevelsBlock(!isBack.value);
}
</script>

<style>
.v-dialog--fullscreen > .v-overlay__content {
  overflow-x: hidden;
}
</style>

<style scoped lang="scss">
@import "src/assets/buttons";
@import "src/assets/slide-animation";

.ask-check-or-setup-level {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .wrapper-change-level {
    position: absolute;

    button {
      display: flex;
      align-items: center;
      margin-top: 15px;
      background-color: var(--dark-pink);
      color: var(--light-yellow);
      padding: 5px 15px;

      &:hover {
        background-color: var(--pink);
      }

      img {
        width: 20px;
        transform: rotateY(180deg);
      }
    }
  }

  .wrapper-preview-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;

    h2 {
      width: 60%;
      text-align: center;
    }

    .wrapper-blocks {
      display: flex;
      gap: 40px;
      margin-bottom: 50px;

      .know-level,
      .check-level {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 20px;
        border-radius: 10px;
        border: 2px solid var(--dark);
        box-shadow: 1px 4px 1px var(--dark);
        background-color: var(--yellow);

        p {
          font-size: 20px;
        }

        .secondary-button {
          padding: 5px 10px;
          background-color: var(--dark-pink);
          color: var(--light-yellow);

          &:hover {
            background-color: var(--pink);
          }
        }
      }
    }
  }
}
</style>
