<template>
  <div class="tariff-block">
    <h2>Тарифный план</h2>

    <div class="wrapper-tariff">
      <h3>{{ userPlan?.name }}</h3>
      <p>дата окончания: {{ getDate(person?.plan_ended_at) }}</p>
    </div>

    <div class="wrapper-tasks-and-message">
      <h3>Остатки по тарифу</h3>
      <p>Заданий - {{ person?.tasks }}</p>
      <p>Сообщений - {{ person?.messages }}</p>
    </div>
  </div>
</template>

<script setup>
import {mainStore} from "@/store/mainStore";
import {storeToRefs} from "pinia/dist/pinia";
import {onMounted, ref, watch} from "vue";
import shared from "@/mixins/shared";

const {getDate} = shared();
const main = mainStore();
const {person, plans} = storeToRefs(main);

let userPlan = ref(null);

onMounted(() => {
  getUsersPlan();
})

watch(plans, () => {
  getUsersPlan();
})

function getUsersPlan() {
  if (!plans.value) return;

  userPlan.value = plans.value.find(plan => plan.id === person.value.last_plan_id);
}
</script>

<style scoped lang="scss">
.tariff-block {
  min-width: 300px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  border: 1px solid var(--dark);
  box-shadow: 1px 4px 1px var(--dark);
  background-color: var(--light_pink);

  .wrapper-tariff {
    h3 {
      color: var(--dark-pink);
      font-weight: 800;
    }
  }

  .wrapper-tasks-and-message {
    border-top: 1px solid var(--dark);
    padding-top: 20px;

    h3 {
      color: var(--dark-pink);
      font-weight: 800;
    }
  }
}

@media screen and (max-width: 1000px){
  .tariff-block {
    max-width: unset;

    .wrapper-tariff {
      h3 {

      }
    }

    .wrapper-tasks-and-message {


      h3 {

      }
    }
  }
}
</style>
