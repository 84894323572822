import {API_URL} from "../../../config";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {mainStore} from "@/store/mainStore";
import {tasksStore} from "@/store/tasksStore";
import {chatStore} from "@/store/chatStore";
import {notificationStore} from "@/store/notificationStore";
import {storeToRefs} from "pinia/dist/pinia";

export default function websocets() {
    const main = mainStore();
    const {changePerson} = main;
    const {person} = storeToRefs(main);
    const taskStore = tasksStore();
    const {changeIsOpenDialog, changeIsOpenRejectDialog, updateTask} = taskStore;
    const chat = chatStore();
    const {
        addNewMessage,
        checkUpdatedData,
        addNewMessageToMainChat,
        changeActiveGeneration,
        changeActiveGenerationMainChat,
        changeActiveLoaderMessageGeneration,
        changeIdHandleMessage,
        updateDataInLastPersonMessage,
    } = chat;
    const notifications = notificationStore();
    const {changeShowConfirmEmailModal} = notifications;

    let host = null;

    function addWebsocet() {
        host = API_URL();
        host = host.split('').splice(8, host.length).join('');
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'reverb',
            key: "t4ocg902zbnp3zdywkcd",
            wssHost: host,
            wsHost: host,
            wsPort: 8080,
            wssPort: 443,
            enabledTransports: ["ws", "wss"],
            forceTLS: true,
            authEndpoint: `${API_URL()}/api/broadcasting/auth`,
            encrypted: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${main.person.token}`
                },
            },
        });

        window.Echo.private(`system.events.${main.person.id}`)
            .listen('.system.events', response => {
                switch (response.model) {
                    case 'User':
                        handleUser(response);
                        return;
                    case 'Message':
                        handleNetworkChat(response);
                        return;
                    case 'UserTaskMessage':
                        if (response.action !== 'created') handleMessageForLesson(response);
                        return;
                    case 'UserTask':
                        handleTasks(response);
                        return;
                }
            })
    }

    function handleUser(response) {
        if (!person.value?.email_verified_at && response.data?.email_verified_at) {
            changeShowConfirmEmailModal(false);
            ym(97227137, 'reachGoal', '343412');
        }
        changePerson(response.data);
    }

    function handleNetworkChat(response) {
        if (!response.data?.response) return;
        addNewMessageToMainChat(response.data.response, response.data?.audio, response.data.id, true, response.data.date);
        changeActiveGenerationMainChat(false);
    }

    function handleMessageForLesson(response) {
        if (taskStore.currentTask?.id === response.data[2]?.task_id) {
            if (taskStore.currentTask?.task?.title !== 'Игра: изображения') checkUpdatedData(response);
            if (response.data[0]?.message) updateDataInLastPersonMessage(response.data[0], response);
        }



        changeActiveGeneration(false);
        changeIdHandleMessage(null);
        changeActiveLoaderMessageGeneration(false);
    }

    function handleTasks(response) {
        if (taskStore.currentTask?.id === response.data?.id) {
            if (response.data.status === 'success' && taskStore.currentTask?.status !== 'success') changeIsOpenDialog(true);
            if (response.data.status === 'failed' && taskStore.currentTask?.status !== 'failed') changeIsOpenRejectDialog(true);

            if (response.data.task.title === 'Игра: изображения' && response.data.properties?.length) {
                changeActiveLoaderMessageGeneration(false);
            }
        }
        updateTask(response.data);
    }

    function deleteWebsocet() {
        if (!host) return;

        window.Echo.private(`system.events.${main.person.id}`)
            .stopListening('.system.events');

        host = null;
    }

    return {
        addWebsocet,
        deleteWebsocet
    }
}
