import {defineStore} from "pinia/dist/pinia";
import {ref} from "vue";

export const tasksStore = defineStore('taskStore', () => {
    let allTasks = ref(null);
    let tasksInCategory = ref(null);
    let userTasks = ref(null);
    let currentTask = ref(null);
    let pagination = ref(null);
    let isOpenFinalModal = ref(false);
    let isOpenRejectModal = ref(false);
    let userErrors = ref(null);
    let isMoveSlider = ref(false);

    function clearTasksStore() {
        allTasks.value = null;
        tasksInCategory.value = null;
        userTasks.value = null;
        currentTask.value = null;
        pagination.value = null;
        isOpenFinalModal.value = false;
        isOpenRejectModal.value = false;
        userErrors.value = null;
    }

    function changeTasks(tasks) {
        allTasks.value = tasks;
    }

    function changeTasksInCategory(tasks) {
        tasksInCategory.value = tasks.reverse();
    }

    function changeUserTasks(tasks) {
        userTasks.value = tasks
    }

    function addToUserTasks(tasks) {
        userTasks.value = [...userTasks.value, ...tasks];
    }

    function changeCurrentTask(task) {
        currentTask.value = task;
    }

    function changeStatusCurrentTask(status) {
        if (currentTask.value?.status) currentTask.value.status = status
    }

    function updateTask(newData) {
        if (!userTasks.value) return;
        const indexUpdateTask = userTasks.value.findIndex(task => task.id === newData.id);
        indexUpdateTask !== -1 ? userTasks.value[indexUpdateTask] = newData : userTasks.value.unshift(newData);
        if (currentTask.value?.id === newData.id) currentTask.value = newData;
    }

    function changePagination(data) {
        pagination.value = data;
    }

    function changeIsOpenDialog(isOpen) {
        isOpenFinalModal.value = isOpen;
    }

    function changeIsOpenRejectDialog(isOpen) {
        isOpenRejectModal.value = isOpen;
    }

    function changeUsersErrors(errors) {
        userErrors.value = errors;
    }

    function toggleIsMoveSlider(isMove) {
        isMoveSlider.value = isMove;
    }

    return {
        allTasks, changeTasks,
        tasksInCategory, changeTasksInCategory,
        userTasks, changeUserTasks, addToUserTasks,
        currentTask, changeCurrentTask, changeStatusCurrentTask, updateTask,
        pagination, changePagination,
        isOpenFinalModal, changeIsOpenDialog,
        isOpenRejectModal, changeIsOpenRejectDialog,
        userErrors, changeUsersErrors,
        isMoveSlider, toggleIsMoveSlider,
        clearTasksStore
    }
})
