<template>
  <v-dialog
      v-model="isOpenDialog"
      width="auto"
  >
    <v-card class="wrapper-reset-password">
      <div class="reset-password-modal">
        <p>Укажити почту для восстановления</p>
        <input v-model="email" placeholder="Почта" @input="handleInput" :class="{'is-error': isShowError}">
        <p class="error-message" v-if="isShowError">{{ errorText }}</p>
        <button class="secondary-button" @click="sentEmail">Отправить</button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {ref, toRefs, watch} from "vue";
import authRequests from "@/mixins/requests/authRequests";

// eslint-disable-next-line no-undef
const props = defineProps({
  isShowResetPassword: Boolean
})
const {isShowResetPassword} = toRefs(props);
const {sendEmailForResetPassword} = authRequests();
const mainErrorText = 'укажите почту'

let isOpenDialog = ref(false);
let email = ref(null);
let isShowError = ref(false);
let errorText = ref(mainErrorText);

watch(isShowResetPassword, () => {
  isOpenDialog.value = true;
})

function handleInput() {
  isShowError.value = !email.value
}

function sentEmail() {
  if (!email.value) {
    isShowError.value = true;
    return;
  }

  errorText.value = mainErrorText;
  isShowError.value = false;
  sendEmailForResetPassword(email.value)
      .then(response => {
        if (response.data?.status === "success") {
          isOpenDialog.value = false
        } else if (response?.request?.status === 404) {
          errorText.value = 'Пользователь не найден';
          isShowError.value = true;
        }
      })
}

</script>

<style lang="scss">
.v-dialog > .v-overlay__content > .v-card {
  border-radius: 20px !important;
}
</style>

<style scoped lang="scss">
@import "src/assets/buttons";

.wrapper-reset-password {
  border-radius: 20px;
  background-color: unset;

  .reset-password-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid var(--dark);
    background-color: var(--light_pink);

    p {
      font-size: 18px;
      font-weight: 800;
    }

    .error-message {
      position: absolute;
      bottom: 60px;
      left: 30px;
      font-size: 15px;
      font-weight: 400;
      color: var(--red);
    }

    input {
      border-radius: 10px;
      border: 2px solid var(--dark);
      box-shadow: 1px 4px 1px var(--dark);
      padding: 5px 10px;
      background-color: var(--light-yellow);

      &::placeholder {
        color: var(--dark);
      }

      &:focus {
        outline: none;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.is-error {
        background-color: var(--red);
      }
    }

    button {
      padding: 5px 10px;
      background-color: var(--yellow);
      margin-top: 5px;

      &:hover {
        background-color: var(--light-yellow);
      }
    }
  }
}
</style>
