<template>
  <div class="price__tariffs-row" :class="{'is-modal-block-wrapper-price': isModalBlock}">
    <template v-for="(tariff, index) in plans" :key="tariff.id">
      <tariff-card-view v-if="checkShowTariff(tariff)"
                        :tariff="tariff"
                        :index="index"
                        :isModalBlock="isModalBlock"
                        :isActiveTariff="person.last_plan_id === tariff.id"
      />
    </template>
  </div>
</template>

<script setup>
import TariffCardView from '@/components/widgets/TariffCardView.vue';
import {mainStore} from "@/store/mainStore";
import {storeToRefs} from "pinia/dist/pinia";

const main = mainStore();
const {person, plans} = storeToRefs(main);

// eslint-disable-next-line no-undef,no-unused-vars
const props = defineProps({
  isModalBlock: Boolean
})

function checkShowTariff(tariff) {
  if (person.value.last_plan_id === tariff.id) {
    return true
  } else {
    return tariff.name !== 'Пробный период';
  }
}
</script>

<style scoped lang="scss">
.price__tariffs-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px;
  gap: 50px;
}

.is-modal-block-wrapper-price {
  padding: unset;
  gap: 20px;
}

@media screen and (max-width: 1000px) {
  .price__tariffs-row {
    padding: 50px 25px;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  .is-modal-block-wrapper-price {
    padding: 0 10px;
    gap: 5px;
  }
}
</style>
